import { FC } from 'react';

import type { ITimelineGroupViewProps } from './TimelineGroupView.type';
import TimelineShiftCollectionView from '../TimelineShiftCollectionView';
import TimelineShiftView from '../TimelineShiftView';

const TimelineGroupView: FC<ITimelineGroupViewProps> = ({
  showSummedShifts = false,
  ...props
}) => {
  if (showSummedShifts) {
    return <TimelineShiftCollectionView {...props} />;
  }

  return <TimelineShiftView {...props} />;
};

export default TimelineGroupView;
