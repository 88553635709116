import { styled, css } from 'styled-components';
import { Utils } from 'team-hero-ui';

import type { TTableStyleValue } from 'components/Table/Table.types';

const { pxToRem, flexFn } = Utils;

const tableViewStyledCommon = css`
  ${flexFn('flex-start', 'start')};
  flex: 1;
`;

interface IMainPanelStyledProps {
  $noHeader?: boolean;
  $styleType?: TTableStyleValue;
}

export const MainPanelWorkplannerStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: ${pxToRem(['auto', '48'])};
  grid-template-areas: 'body' 'pagination';
  min-height: 100%;
`;

export const TableViewStyled = styled.div`
  ${tableViewStyledCommon};
  height: 100%;
  overflow-x: auto;
  padding: ${pxToRem([10, 0, 20, 0])};
  background-color: ${({ theme }) => theme.colors.greyShades.grey6};
`;

export const MainPanelStyled = styled.div<IMainPanelStyledProps>`
  width: 100%;
  display: grid;
  grid-template-rows: ${({ $noHeader }) =>
    $noHeader
      ? pxToRem(['auto', '48'])
      : `min-content ${pxToRem(['auto', '48'])}`};
  grid-template-areas: ${({ $noHeader }) =>
    $noHeader ? "'body' 'pagination'" : "'header' 'body' 'pagination'"};
  height: 100%;
  position: relative;
`;

export const TableBodyWrapperStyled = styled.div`
  width: 100%;
  display: grid;
  grid-area: body;
  grid-template-areas: 'bulk-top' 'table' 'bulk-bottom';
  grid-template-rows: ${pxToRem('40')} auto ${pxToRem('40')};
  overflow-y: auto;
`;

export const BulkTopStyled = styled.div`
  grid-area: bulk-top;
`;

export const BulkBottomStyled = styled.div`
  grid-area: bulk-bottom;
`;

export const TableWrapperStyled = styled.div`
  width: fit-content;
  grid-area: table;
  min-width: 100%;
  overflow: auto;
  position: relative;
  padding: ${pxToRem([5, 20, 5, 25])};
`;

export const TableViewWorkplannerStyled = styled(TableWrapperStyled)`
  ${flexFn('center', 'stretch')}
  padding: ${pxToRem([10, 0])};
  grid-area: main;
  min-height: 100%;
`;
