import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TimesheetApprovalContainer,
  TimesheetApprovalItem,
  TextField,
} from 'team-hero-ui';
import { Controller } from 'react-hook-form';

import type { IClientApprovalModalListProps } from 'components/Modals/ClientApprovalModal/ClientApprovalModalList/ClientApprovalModalList.types';
import Avatar from 'components/Avatar';
import { ClientApprovalBodyStyled } from 'components/Modals/ClientApprovalModal/ClientApprovalModalList/ClientApprovalModalList.styled';
import { useDateFormat } from 'hooks/useDateFormat.hook';

const ClientApprovalModalList: FC<IClientApprovalModalListProps> = ({
  timesheetsFields,
  control,
  setIsApproved,
}) => {
  const { t } = useTranslation();

  const { formatDate } = useDateFormat();

  return (
    <ClientApprovalBodyStyled>
      <TimesheetApprovalContainer
        nameLabel={t('modal.labels.name')}
        startLabel={t(
          'work.modals.manualTimetracking.form.startDateTime.label'
        )}
        endLabel={t('work.modals.manualTimetracking.form.endDateTime.label')}
        positionLabel={t('work.modals.expenses.labels.shiftPosition')}
        durationLabel={t('modal.labels.duration')}
      >
        {timesheetsFields.map((timesheet, index) => (
          <Controller
            key={`approveTimesheets.${timesheet.id}.note`}
            name={`approveTimesheets.${index}.note`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TimesheetApprovalItem
                key={timesheet.timesheetId}
                id={timesheet.timesheetId}
                avatar={
                  <Avatar
                    sourceType='resource'
                    images={timesheet.avatar || ''}
                    firstName={timesheet.firstName}
                    lastName={timesheet.lastName}
                    size='M'
                    thumbnailSize={'small'}
                  />
                }
                name={`${timesheet.firstName} ${timesheet.lastName}`}
                position={timesheet.position || '-'}
                startTime={formatDate(timesheet.start, 'time')}
                endTime={formatDate(timesheet.end, 'time')}
                startDate={formatDate(timesheet.start, 'dateShort')}
                endDate={formatDate(timesheet.end, 'dateShort')}
                duration={timesheet.duration}
                isBreak={timesheet.isBreak}
                approved={timesheet.isApproved}
                setIsApproved={(_id, selectedValue) =>
                  setIsApproved(index, selectedValue)
                }
                input={
                  <TextField
                    label={t('modal.labels.reasonForNotApproving')}
                    value={value as string}
                    onChange={onChange}
                    styleType='modal'
                    multiline
                  />
                }
              />
            )}
          />
        ))}
      </TimesheetApprovalContainer>
    </ClientApprovalBodyStyled>
  );
};
export default ClientApprovalModalList;
