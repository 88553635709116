import { styled } from 'styled-components';
import { Box, Button, IconButton, Theme, Utils, device } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ContainerStyled = styled.div`
  height: 100%;
  background-color: ${Theme.colors.greyShades.grey7};
  padding: ${pxToRem(8)};
  overflow: auto;
  ${flexFn('flex-end', 'center', 'column-reverse')};

  @media ${device.sm} {
    padding: ${pxToRem(16)};
    ${flexFn('flex-end', 'center', 'row')};
  }
`;

export const ItemStyled = styled.div`
  ${flexFn('center', 'start', 'column')};
  width: 100%;
`;

export const LogoItemStyled = styled(ItemStyled)`
  ${flexFn('flex-end', 'center', 'column')};
`;

export const LogoWrapperStyled = styled.div`
  ${flexFn('center', 'center', 'column')};
  width: 100%;

  svg {
    max-width: 100%;
    width: ${pxToRem(300)};
    height: auto;
  }
`;

export const ContentWrapperStyled = styled(Box)`
  ${flexFn('center', 'start', 'column')};
  gap: ${pxToRem(8)};
  max-width: ${pxToRem(350)};
  padding: ${pxToRem(20)};
  height: 100%;
`;

export const ErrorH1 = styled.h1`
  color: ${Theme.colors.primary.red};
  font-size: ${pxToRem(50)};
  font-weight: 600;
  text-transform: uppercase;
`;

export const ErrorH2 = styled.h2`
  color: ${Theme.colors.primary.grey};
  font-size: ${pxToRem(15)};
  font-weight: 600;
  text-transform: uppercase;
`;

export const DescriptionStyled = styled.p`
  padding: ${pxToRem([24, 0])};
  color: ${Theme.colors.primary.grey};
  font-size: ${pxToRem(12)};
  line-height: 1.67;
`;

export const ButtonWrapperStyled = styled.div`
  ${flexFn('center', 'start', 'row')};
  gap: ${pxToRem(12)};
`;

export const ButtonStyled = styled(Button)`
  &.MuiButtonBase-root {
    &.MuiButton-text {
      font-size: ${pxToRem(12)};
    }
  }
`;

export const IconButtonStyled = styled(IconButton)`
  &.MuiButtonBase-root {
    position: absolute;
    top: ${pxToRem(20)};
    right: ${pxToRem(20)};
  }
`;
