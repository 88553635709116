import moment, { Moment, Duration } from 'moment';

import type { ITimelineBackdropItem } from './TimelineBackdrop.types';
import type {
  TResolutionType,
  TTimeOffsetType,
} from 'components/Timeline/interfaces/timelineResolutions.interface';
import {
  getPixelIncrement,
  isInWorkingTime,
} from 'components/Timeline/helper/timelineItemsCalculate.helper';

export const calculateBackdropItems = (
  barResolution: TResolutionType,
  start: Moment,
  end: Moment,
  backdropWidth: number
): ITimelineBackdropItem[] => {
  const currentDate = start.clone();
  const timeIncrements: ITimelineBackdropItem[] = [];
  const calculateWidth = backdropWidth;
  // left offset must be added
  let pixelsLeft = calculateWidth;

  const addTimeIncrement = (
    initialOffset: Duration,
    offsetType: TTimeOffsetType,
    stepFunc: (x: Moment, y: Duration) => void
  ) => {
    let offset = null;
    while (currentDate.isBefore(end) && pixelsLeft > 0) {
      // if this is the first 'block' it may be cut off at the start
      if (pixelsLeft === calculateWidth) {
        offset = initialOffset;
      } else {
        offset = moment.duration(0);
      }
      const pixelIncrements = Math.min(
        getPixelIncrement(
          currentDate,
          barResolution,
          start,
          end,
          offset.as(offsetType),
          calculateWidth
        ),
        pixelsLeft
      );

      timeIncrements.push({
        size: pixelIncrements,
        key: pixelsLeft,
        striped: !isInWorkingTime(barResolution, currentDate),
        startTime: currentDate.clone(),
      });
      stepFunc(currentDate, offset);
      pixelsLeft -= pixelIncrements;
    }
  };

  if (barResolution === 'year') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('year'))
    );
    addTimeIncrement(offset, 'months', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'year')
    );
  } else if (barResolution === 'month') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('month'))
    );
    addTimeIncrement(offset, 'days', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'month')
    );
  } else if (barResolution === 'day') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('day'))
    );
    addTimeIncrement(offset, 'hours', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'days')
    );
  } else if (barResolution === 'hour') {
    const offset = moment.duration(
      currentDate.diff(currentDate.clone().startOf('hour'))
    );
    addTimeIncrement(offset, 'minutes', (currentDt, offst) =>
      currentDt.subtract(offst).add(1, 'hours')
    );
  }

  return timeIncrements;
};

export const calculateCurrentDateLineOffset = (
  start: Moment,
  end: Moment,
  backdropWidth: number
): number => {
  const currentDate = moment().clone();

  const startToCurrentMs = currentDate.diff(start, 'milliseconds');

  const startToEndMs = end.diff(start, 'milliseconds');
  const pixelsPerMs = backdropWidth / startToEndMs;
  return Math.min(Math.max(pixelsPerMs * startToCurrentMs, 0), backdropWidth);
};
