import { useGetUserNotificationsQuery } from 'services/teamHeroApi.service';
import { UserNotificationConfig } from 'config/userNotification.config';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';

interface IUseGetUnreadNotificationCountReturn {
  newNotificationsCount?: number;
}

const useGetUnreadNotificationCount = (
  skip = false
): IUseGetUnreadNotificationCountReturn => {
  const { currentUser } = useCurrentUserContact();
  const { data: newNotifications } = useGetUserNotificationsQuery(
    {
      filters: [
        {
          key: 'isRead',
          operator: 'AND',
          value: false,
        },
      ],
      itemsPerPage: 0,
      page: 1,
    },
    {
      skip: !currentUser || skip,
      pollingInterval: UserNotificationConfig.pollingInterval,
    }
  );

  return {
    newNotificationsCount: newNotifications?.totalCount,
  };
};

export default useGetUnreadNotificationCount;
