import { styled } from 'styled-components';
import { device, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const MissionOverviewTabStyled = styled.div`
  height: 100%;
`;

export const OverviewDetailsStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  overflow: auto;
  gap: ${pxToRem(24)};
  padding: ${pxToRem(24)};

  @media ${device.md} {
    height: calc(100dvh - ${pxToRem(108)});
  }
`;

export const BriefingTitleStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(12)};
  font-size: ${pxToRem(15)};
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
`;

export const MissionTasksStyled = styled.div`
  ${flexFn('flex-start', 'center')};

  gap: ${pxToRem(24)};
`;
