import { styled } from 'styled-components';
import { device, Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ClientLoginMissionDetailsStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  height: calc(100dvh - ${pxToRem(64)});
  background-color: ${Theme.colors.greyShades.grey5};

  @media ${device.sm} {
    height: 100%;
  }
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 100dvh;
`;
