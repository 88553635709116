import { styled } from 'styled-components';
import { Theme, Utils, device, Button } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

type TJustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

type TAlignItems = 'center' | 'start' | 'end' | 'stretch' | 'baseline';

type TFlexDirection = 'row' | 'column';

type TFlexWrap =
  | 'inherit'
  | 'initial'
  | 'wrap'
  | 'nowrap'
  | 'wrap-reverse'
  | 'unset'
  | 'revent';

interface IProjectMissionInfoSectionProps {
  $justifyContent?: TJustifyContent;
  $alignItems?: TAlignItems;
  $flexDirection?: TFlexDirection;
  $margin?: number[] | number;
  $gap?: number;
  $flexWrap?: TFlexWrap;
  $width?: string;
}

interface IProjectMissionInfoWrapperProps {
  $justifyContent?: TJustifyContent;
  $alignItems?: TAlignItems;
  $flexDirection?: TFlexDirection;
}

export const ProjectMissionInfoWrapper = styled.div<IProjectMissionInfoWrapperProps>`
  width: 100%;
  height: auto;
  padding: ${pxToRem(16)};
  padding-bottom: 0;
  background-color: ${Theme.colors.primary.white};
  color: ${Theme.colors.primary.grey};
  ${({ $justifyContent, $alignItems, $flexDirection }) =>
    flexFn($justifyContent, $alignItems, $flexDirection)}
  overflow: auto;

  @media ${device.sm} {
    height: calc(100dvh - ${pxToRem(60)});
    padding-bottom: ${pxToRem(16)};
  }

  @media ${device.lg} {
    height: 100dvh;
  }
`;

export const ProjectMissionInfoSection = styled.div<IProjectMissionInfoSectionProps>`
  width: ${({ $width }) => ($width ? $width : '100%')};
  margin: ${({ $margin }) =>
    $margin !== undefined
      ? pxToRem($margin)
      : pxToRem([device.xs ? 12 : 24, 0, 0, 0])};
  height: auto;
  ${({ $justifyContent, $alignItems, $flexDirection }) =>
    flexFn($justifyContent || 'flex-start', $alignItems, $flexDirection)}
  ${({ $gap }) => ($gap ? `gap: ${pxToRem($gap)}` : null)}
  ${({ $flexWrap }) => ($flexWrap ? `flex-wrap: ${$flexWrap};` : null)}
`;

export const GreyThinText = styled.span`
  color: ${Theme.colors.greyShades.grey3};
  font-weight: 300;
  line-height: ${pxToRem(16)};
  font-size: ${pxToRem(12)};

  @media ${device.md} {
    line-height: ${pxToRem(18)};
    font-size: ${pxToRem(14)};
  }
`;

export const DefaultText = styled.span`
  color: ${Theme.colors.primary.grey};
  line-height: ${pxToRem(16)};
  font-size: 12px;

  @media ${device.sm} {
    line-height: ${pxToRem(18)};
    font-size: ${pxToRem(14)};
  }
`;

export const ActionsSection = styled.div`
  ${flexFn('flex-start', 'start', 'column')};
  gap: ${pxToRem(24)};
  margin-top: ${pxToRem(24)};
`;

export const DescriptionSectionStyled = styled(ProjectMissionInfoSection)`
  border: ${pxToRem(1)} solid ${Theme.colors.greyShades.grey4};
  border-radius: ${pxToRem(6)};
  padding: ${pxToRem(12)};
  color: ${Theme.colors.primary.grey};
  cursor: pointer;
`;

export const DescriptionHeaderStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(12)};
  font-size: ${pxToRem(18)};
  font-weight: 600;
`;

export const TitleWrapperStyled = styled.div`
  ${flexFn('space-between')};
  width: 100%;
  margin-bottom: ${pxToRem(16)};
  font-size: ${pxToRem(22)};
  font-weight: 600;
`;

export const DescriptionContentStyled = styled(DefaultText)`
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: ${pxToRem(150)};
  ul,
  ol {
    padding-left: ${pxToRem(20)};
  }

  > ul,
  > ol {
    margin-bottom: ${pxToRem(20)};
  }
`;

export const LeaderNameStyled = styled.p`
  font-size: ${pxToRem(13)};
`;

export const TeamLeaderSectionStyled = styled(ProjectMissionInfoSection)`
  position: relative;
  cursor: pointer;

  .MuiSvgIcon-root {
    cursor: pointer;
    pointer-events: none;
    position: absolute;
    left: ${pxToRem(18)};
    top: ${pxToRem(18)};
  }
`;

export const AddressButtonStyled = styled(Button)`
  &.MuiButtonBase-root {
    font-size: ${pxToRem(14)};
    line-height: 1;
    text-align: left;
  }
`;
