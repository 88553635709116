import { styled, css } from 'styled-components';
import { device, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

interface IFooterStyledProps {
  $wrapFooterElements: boolean;
}

export const PaginationFooterStyled = styled.div<IFooterStyledProps>`
  .MuiButton-contained {
    text-transform: inherit;
    color: ${({ theme }) => theme.colors.greyShades.grey2};
    min-width: ${pxToRem(190)};
  }

  grid-area: pagination;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: ${pxToRem(5)};
  margin-top: ${pxToRem(8)};
  padding: ${pxToRem([0, 20])};
  gap: ${pxToRem(10)};

  ${({ $wrapFooterElements }) =>
    $wrapFooterElements &&
    css`
      ${flexFn('center', 'center', 'column')}
    `}
`;

export const DropdownWrapperStyled = styled.div`
  ${flexFn('flex-start')}
`;

interface IPaginationWrapperStyledProps {
  $withPagesDropdown?: boolean;
}

export const PaginationWrapperStyled = styled.div<IPaginationWrapperStyledProps>`
  ${({ $withPagesDropdown }) =>
    flexFn($withPagesDropdown ? 'flex-end' : 'center')};

  width: ${({ $withPagesDropdown }) => ($withPagesDropdown ? 'unset' : '100%')};
  @media ${device.sm} {
    ${flexFn()}
  }
`;
