import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Icons } from 'team-hero-ui';

import type { IMissionDetailsHeaderProps } from './MissionDetailsHeader.types';
import Avatar from 'components/Avatar';
import { DateCellRenderer } from 'components/cellRenderers/DateCellRenderer/DateCellRenderer';
import {
  ContentStyled,
  HeaderStyled,
  MissionDatesStyled,
  MissionDetailsHeaderStyled,
  MissionDetailsStyled,
  MissionTitleStyled,
  MissionTitleWrapperStyled,
} from './MissionDetailsHeader.styled';
import { ProjectTitleCellRenderer } from 'components/cellRenderers/ProjectTitleCellRenderer/ProjectTitleCellRenderer';

const MissionDetailsHeader: FC<IMissionDetailsHeaderProps> = ({
  mission,
  children,
}) => {
  const navigate = useNavigate();

  const onCloseClick = () => {
    navigate('/work');
  };

  return (
    <MissionDetailsHeaderStyled>
      <HeaderStyled>
        {mission ? (
          <>
            <MissionTitleWrapperStyled>
              <ProjectTitleCellRenderer mission={mission} />
              <MissionTitleStyled>{mission.title}</MissionTitleStyled>
            </MissionTitleWrapperStyled>
            <MissionDetailsStyled>
              <MissionDatesStyled>
                {mission?.start && <DateCellRenderer date={mission.start} />}
                {mission?.end && <DateCellRenderer date={mission.end} />}
              </MissionDatesStyled>
              {mission?.leader && (
                <Avatar
                  sourceType='file'
                  images={mission.leader.avatar}
                  embeddedContact={mission.leader}
                  thumbnailSize='small'
                />
              )}
              <IconButton onClick={onCloseClick}>
                <Icons.CloseIcon svgColor='currentColor' svgSize={30} />
              </IconButton>
            </MissionDetailsStyled>
          </>
        ) : null}
      </HeaderStyled>
      <ContentStyled>{children}</ContentStyled>
    </MissionDetailsHeaderStyled>
  );
};

export default MissionDetailsHeader;
