import { styled } from 'styled-components';
import { Button, ChipLabel, device, Theme, Utils } from 'team-hero-ui';

import { shadowLight } from 'helpers/styles/styles.helper';

const { flexFn, pxToRem } = Utils;

export const RetireCardStyled = styled.div`
  overflow: hidden;
  ${shadowLight};
  max-width: 100%;

  @media ${device.sm} {
    width: ${pxToRem(500)};
    border-radius: ${pxToRem(4)};
  }
`;

export const ChatIconStyled = styled.div`
  margin-bottom: ${pxToRem(-34)};
`;

export const RetireTextStyled = styled.p`
  font-size: ${pxToRem(24)};

  @media ${device.md} {
    font-size: ${pxToRem(28)};
  }
`;

export const RetireTitleStyled = styled.div`
  ${flexFn('space-evenly')};
  flex-flow: wrap;
  gap: ${pxToRem(12)};
  overflow: hidden;
  color: ${Theme.colors.others.green.green1};
  background-color: ${Theme.colors.others.green.green4};
  padding: ${pxToRem([0, 24])};
  @media ${device.sm} {
    padding: ${pxToRem([0, 30])};
  }
`;

export const LoginMessageTextStyled = styled.p`
  color: ${Theme.colors.primary.grey};
  font-size: ${pxToRem(13)};
`;

export const LoginMessageStyled = styled.div`
  background-color: ${Theme.colors.primary.white};
  white-space: pre-line;
  padding: ${pxToRem(20)};
`;

export const BottomSectionStyled = styled.div`
  ${flexFn('space-between')};
  padding-top: ${pxToRem(12)};
  flex-wrap: wrap;
  gap: ${pxToRem(10)};
`;

export const AddButtonStyled = styled(Button)`
  &&& {
    font-size: ${pxToRem(13)};
    color: ${Theme.colors.primary.grey};
  }
`;

export const RetiredLabelStyled = styled(ChipLabel.General)`
  white-space: nowrap;
`;
