import { IconButton, Icons, theme, Tooltip } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import type { IUseSortReturn } from 'components/TableView/hooks/useSort.hook';
import type { TSortDirection } from 'interfaces/Pagination.interface';
import type { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import {
  ButtonWrapper,
  StyledTableHeaderCell,
  StyledTableHeaderRow,
  StyledTableHeaderSortWrapper,
} from './TableHeader.styled';
import { getGridColsWidth } from 'components/Table/components/util';

interface ITableHeaderProps<T> {
  columns: Array<IColumnDefinitionType<T>>;
  defaultCellWidth?: string;
  sorting: IUseSortReturn;
}

//eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const TableHeader = <T extends unknown>({
  columns,
  defaultCellWidth = '50px',
  sorting,
}: ITableHeaderProps<T>) => {
  const { t } = useTranslation('general');

  const renderCell = (column: IColumnDefinitionType<T>) => {
    const onUpClick = () => {
      sorting.onSetSorting(column.sortKey ? column.sortKey : column.key, 'asc');
    };
    const onDownClick = () => {
      sorting.onSetSorting(
        column.sortKey ? column.sortKey : column.key,
        'desc'
      );
    };
    const checkIfIconIsSelected = (type: TSortDirection) => {
      const keyToCompare = column.sortKey || column.key;
      return keyToCompare === sorting.columnKey && type === sorting.direction;
    };

    return (
      <StyledTableHeaderCell
        key={`headCell-${column.key}`}
        $minWidth={column.minWidth || defaultCellWidth}
        $width={column.width}
        $isSorted={
          column.key === sorting.columnKey ||
          column.sortKey === sorting.columnKey
        }
      >
        {column.label}
        {column.isSortable && (
          <StyledTableHeaderSortWrapper>
            <Tooltip
              title={t(
                `table.header.sorting.up.${column.sortType || 'string'}`
              )}
              displayArrow
              styleType='top'
            >
              <ButtonWrapper $keepVisible={checkIfIconIsSelected('asc')}>
                <IconButton size='small' onClick={onUpClick}>
                  <Icons.CaretUpIcon
                    svgSize={14}
                    svgColor={
                      checkIfIconIsSelected('asc')
                        ? theme.colors.greyShades.grey2
                        : theme.colors.greyShades.grey3
                    }
                  />
                </IconButton>
              </ButtonWrapper>
            </Tooltip>
            <Tooltip
              title={t(
                `table.header.sorting.down.${column.sortType || 'string'}`
              )}
              displayArrow
              styleType='top'
            >
              <ButtonWrapper $keepVisible={checkIfIconIsSelected('desc')}>
                <IconButton size='small' onClick={onDownClick}>
                  <Icons.CaretDownIcon
                    svgSize={14}
                    svgColor={
                      checkIfIconIsSelected('desc')
                        ? theme.colors.greyShades.grey2
                        : theme.colors.greyShades.grey3
                    }
                  />
                </IconButton>
              </ButtonWrapper>
            </Tooltip>
          </StyledTableHeaderSortWrapper>
        )}
      </StyledTableHeaderCell>
    );
  };

  const headers = columns.map((column) => {
    return column.visibility ? renderCell(column) : null;
  });

  return (
    <StyledTableHeaderRow
      data-component='table-header-row'
      $gridColsWidth={getGridColsWidth(columns)}
    >
      {headers}
    </StyledTableHeaderRow>
  );
};

export default TableHeader;
