import moment, { Moment } from 'moment';

import type { IWorkplannerSettingState } from 'components/Workplanner/Workplanner.interface';
import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import { calculateTimeLineEnd } from 'components/Timeline/helper/timelineSettings.helper';

const getWorkplannerSettingsByMission = (
  currentDate: Moment,
  mission: IProjectMission,
  prevState: IWorkplannerSettingState
): IWorkplannerSettingState => {
  // case 1 - mission is in the past
  if (moment(mission.end || undefined).isBefore(currentDate)) {
    // return last week of mission
    const startDate = moment(mission.end || undefined).startOf('isoWeek');
    return {
      ...prevState,
      startDate,
      endDate: calculateTimeLineEnd(prevState.timelineSize, startDate),
    };
  }

  // case 2 - mission is active
  if (
    moment(mission.start || undefined).isBefore(currentDate) &&
    moment(mission.end || undefined).isAfter(currentDate)
  ) {
    // return current week
    const startDate = moment(currentDate).clone().startOf('isoWeek');
    return {
      ...prevState,
      startDate,
      endDate: calculateTimeLineEnd(prevState.timelineSize, startDate),
    };
  }

  // case 3 - mission is in the future
  if (moment(mission.start || undefined).isAfter(currentDate)) {
    // return first week of mission
    const startDate = moment(mission.start || undefined).startOf('isoWeek');
    return {
      ...prevState,
      startDate,
      endDate: calculateTimeLineEnd(prevState.timelineSize, startDate),
    };
  }

  const startDate = moment(mission.start || undefined).startOf('isoWeek');
  return {
    ...prevState,
    startDate,
    endDate: calculateTimeLineEnd(prevState.timelineSize, startDate),
  };
};

export default getWorkplannerSettingsByMission;
