import type { IMessageConversation } from 'interfaces/MessageConversation.interface';
import type {
  IProjectMission,
  IProjectMissionEmbed,
} from 'interfaces/ProjectMission.interface';
import {
  checkEmbedIsMission,
  checkEmbedIsProject,
  checkEmbedIsProjectMini,
} from 'helpers/embed/embedTypeCheck.helper';

interface IGetProjectAndMissionTitleResponse {
  projectTitle: string | null;
  missionTitle: string | null;
}

export const getProjectAndMissionTitle = (
  conversation: IMessageConversation
): IGetProjectAndMissionTitleResponse => {
  if (checkEmbedIsProject(conversation.related)) {
    return {
      projectTitle: conversation.related.title,
      missionTitle: '',
    };
  } else if (checkEmbedIsMission(conversation.related)) {
    if (checkEmbedIsProject(conversation.related.project)) {
      return {
        projectTitle: conversation.related.project.title,
        missionTitle: conversation.related.title,
      };
    }
    if (checkEmbedIsProjectMini(conversation.related.project)) {
      return {
        projectTitle: conversation.related.project['@id'],
        missionTitle: conversation.related.title,
      };
    }
    return {
      projectTitle: conversation.related.project,
      missionTitle: conversation.related.title,
    };
  }
  return { projectTitle: '', missionTitle: '' };
};

export const getProjectAndMissionTitleFromMission = (
  mission?: IProjectMission | IProjectMissionEmbed
): IGetProjectAndMissionTitleResponse => {
  if (!mission) {
    return { projectTitle: null, missionTitle: null };
  }

  if (checkEmbedIsProject(mission.project)) {
    return {
      projectTitle: mission.project.title,
      missionTitle: mission.title,
    };
  }
  if (checkEmbedIsProjectMini(mission.project)) {
    return {
      projectTitle: mission.project['@id'],
      missionTitle: mission.title,
    };
  }
  return {
    projectTitle: mission.project,
    missionTitle: mission.title,
  };
};
