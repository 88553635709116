import { FC } from 'react';

import LayoutNoPermission from 'components/LayoutNoPermission';
import { NoPermissionWrapper } from './NoPermission.styled';

const NoPermission: FC = () => (
  <NoPermissionWrapper>
    <LayoutNoPermission />
  </NoPermissionWrapper>
);

export default NoPermission;
