import { FC } from 'react';
import { Grid, Loader, Utils, device } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import {
  DashboardHeaderStyled,
  DashboardStyled,
  DashboardWrapperStyled,
  HeaderTitleStyled,
  HeaderTitleWrapperStyled,
} from './RetiredLoginDashboard.styled';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import RetireCard from 'components/Dashboard/RetireCard';
import { LogoStyled } from 'components/LayoutLogin/LayoutLogin.styled';
import { useGetPublicSystemSettingsQuery } from 'services/publicTeamHeroApi.service';

const RetiredLoginDashboard: FC = () => {
  const { t } = useTranslation('general');
  const { useMediaQuery } = Utils;
  const isSmScreenSize = useMediaQuery(device.sm);
  const { currentUserContact, isLoading } = useCurrentUserContact();

  const { data: systemSettingsData, isLoading: isLoadingSystemSettings } =
    useGetPublicSystemSettingsQuery();

  const logoUrlPath =
    systemSettingsData?.settings.branding?.client_logo_icon?.file;

  const getLogoUrlBasePath = (): string => {
    if (
      import.meta.env.MODE === 'development' ||
      import.meta.env.MODE === 'mobile'
    ) {
      return import.meta.env.VITE_API_URI || '';
    }
    return '';
  };

  const firstName = currentUserContact?.firstName;
  const lastName = currentUserContact?.lastName;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DashboardStyled>
      {!isSmScreenSize && (
        <DashboardHeaderStyled>
          {!isLoadingSystemSettings && logoUrlPath && (
            <LogoStyled
              src={getLogoUrlBasePath() + logoUrlPath}
              alt={
                systemSettingsData?.settings.branding?.client_logo_icon?.alt ||
                'logo'
              }
            />
          )}
          <HeaderTitleWrapperStyled>
            <HeaderTitleStyled>
              {t('dashboard.greeting', {
                name: `${firstName} ${lastName}`,
              })}
            </HeaderTitleStyled>
          </HeaderTitleWrapperStyled>
        </DashboardHeaderStyled>
      )}

      <DashboardWrapperStyled>
        <Grid p={isSmScreenSize ? 1 : 0} container spacing={0}>
          <RetireCard />
        </Grid>
      </DashboardWrapperStyled>
    </DashboardStyled>
  );
};

export default RetiredLoginDashboard;
