import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const MissionDetailsHeaderStyled = styled.div`
  height: 100%;
  ${flexFn('flex-start', 'stretch', 'column')};
`;

export const HeaderStyled = styled.div`
  ${flexFn('space-between', 'center')};
  height: ${pxToRem(60)};
  background-color: ${Theme.colors.primary.white};
  padding: ${pxToRem([0, 16, 0, 24])};
`;

export const MissionTitleWrapperStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(12)};
`;

export const MissionTitleStyled = styled.p`
  font-size: ${pxToRem(20)};
  color: ${Theme.colors.primary.grey};
`;

export const MissionDatesStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(12)};
`;

export const ContentStyled = styled.div`
  flex: 1;
`;

export const MissionDetailsStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(24)};
`;
