import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ITimetrackingListProps } from './TimetrackingList.types';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import type { IProjectTimesheet } from 'interfaces/ProjectTimesheet.interface';
import {
  paginatedTimetrackingsSelector,
  setItemsPerPage,
  setNextPage,
  setSorting,
  setMenuColumnList,
} from 'redux/table/timetrackings/timetrackings.slice';
import {
  timetrackingsColumnsFactory,
  columnsOffset,
} from 'config/tableColumnConfigurations/TimetrackingsColumns.config';
import { useAppSelector } from 'store/hooks';
import { TableView } from 'components/TableView/TableView';
import { usePagination } from 'hooks/usePagination.hook';
import { useSort } from 'components/TableView/hooks/useSort.hook';
import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import { useRowSelection } from 'components/TableView/hooks/useRowSelection.hook';
import { useGetProjectTimesheetsQuery } from 'services/teamHeroApi.service';
import { getDuringStringHelper } from 'helpers/dateTime/getDuringString.helper';
import useClientLoginMissionSettings from 'hooks/useClientLoginMissionSettings.hook';
import { useTimetrackingsSummaryRow } from '../hooks/useTimetrackingsSummaryRow.hook';
import TimesheetRowEdit from 'components/Table/components/RowAction/TimesheetRowEdit';
import { useClientApprovalModalByIds } from 'pages/LiveMode/hooks/modals/useClientApprovalModalByIds.hook';
import TimetrackingBulkEdit from 'components/Table/components/BulkEditRow/TimetrackingBulkEdit';

export const TimetrackingListTable: FC<ITimetrackingListProps> = ({
  id,
  projectId,
  filterPropertyName,
  headerLeftContent,
  timetrackingSettings,
  timetrackingCenterHeader,
}) => {
  const { t } = useTranslation('general');
  const { enableClientContacts } = useClientLoginMissionSettings({
    missionId: id,
  });
  const { clientApprovalModal, openClientApprovalModalWithIds } =
    useClientApprovalModalByIds({
      modalTitle: t('table.action.timesheetsApprove'),
    });

  const filters: ISimpleFilter[] = [
    { key: filterPropertyName, operator: 'AND', value: id },
    { key: 'status', operator: 'AND', value: 'approved' },
    {
      key: 'during',
      operator: 'AND',
      value: getDuringStringHelper(
        timetrackingSettings.startDate.toDate(),
        timetrackingSettings.endDate.toDate()
      ),
    },
  ];

  const { pagination, sorting } = useAppSelector(
    paginatedTimetrackingsSelector
  );

  const {
    data: timetrackings,
    isLoading,
    isFetching,
    isError,
  } = useGetProjectTimesheetsQuery({
    ...createPaginationApiArgs(pagination, sorting),
    filters,
  });

  const paginationData = usePagination(
    1,
    setNextPage,
    setItemsPerPage,
    paginatedTimetrackingsSelector
  );

  const sortingData = useSort(setSorting, paginatedTimetrackingsSelector);

  const { rowSelectionProps, bulkEdit } = useRowSelection<IProjectTimesheet>(
    timetrackings?.items,
    undefined,
    (selectedRowsIds: number[]) => (
      <TimetrackingBulkEdit
        selectedRowsIds={selectedRowsIds}
        onBulkClientApproval={openClientApprovalModalWithIds}
      />
    )
  );

  const { summaryBottomRow } = useTimetrackingsSummaryRow({
    enableClientContacts,
    hidden: !timetrackings?.items.length || !projectId,
    projectId,
    missionId: id,
    startDate: timetrackingSettings.startDate,
    endDate: timetrackingSettings.endDate,
    timetrackings: timetrackings?.items || [],
  });

  if (isError) {
    throw new Error(t('errors:errorOccurred'));
  }

  return (
    <>
      {clientApprovalModal}
      <TableView
        columns={timetrackingsColumnsFactory({
          displayContactAsDefault: enableClientContacts,
          t,
          rowSelectionProps,
        })}
        headerLeftAdditional={headerLeftContent}
        headerCenterSection={timetrackingCenterHeader}
        bulkEditRow={bulkEdit}
        data={timetrackings?.items || []}
        isLoading={isLoading}
        editRowComponent={() => (
          <TimesheetRowEdit
            rowSelectionProps={rowSelectionProps}
            onClientApprovalClick={(idToOpen) =>
              openClientApprovalModalWithIds([idToOpen])
            }
          />
        )}
        isFetching={isFetching}
        itemsTotalCount={timetrackings?.totalCount || 0}
        pagination={paginationData}
        rowIdMenuOpen={rowSelectionProps.rowIdMenuOpen}
        columnsOffset={columnsOffset}
        sorting={sortingData}
        onRowClick={() => {}}
        bottomRowRenderer={summaryBottomRow}
        isMin={false}
        paginationSelector={paginatedTimetrackingsSelector}
        setColumnListDispatch={setMenuColumnList}
        disableColumnSelection={true}
      />
    </>
  );
};
