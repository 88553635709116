import { TFunction } from 'i18next';
import { Theme } from 'team-hero-ui';

import { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import { GrayedCellRenderer } from 'components/cellRenderers/wta';
import { RatingScoreCellRenderer } from 'components/cellRenderers/RatingScoreCellRenderer/RatingScoreCellRenderer';
import { IMissionRatingSummaryRow } from '../MissionRatingsTab.types';

const customWidths = {
  S: '100px',
  M: '200px',
  L: '300px',
};

interface IRatingsColumnsFactoryProps {
  enableClientContacts: boolean;
  t: TFunction;
}

const getGreyedLightEmptyCellRenderer = () => (
  <GrayedCellRenderer bgColor={Theme.colors.greyShades.grey5} />
);

export const ratingsSummaryColumnsFactory = ({
  t,
  enableClientContacts,
}: IRatingsColumnsFactoryProps): IColumnDefinitionType<IMissionRatingSummaryRow>[] => {
  return [
    {
      key: 'beginSeparator',
      label: '',
      visibility: true,
      minWidth: '14px',
      width: '14px',
      cellType: 'custom',
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
    {
      key: 'ratedBy',
      label: '',
      visibility: enableClientContacts,
      displayInExport: enableClientContacts,
      minWidth: customWidths.M,
      cellType: 'custom',
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
    {
      key: 'position',
      label: '',
      visibility: true,
      cellType: 'custom',
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
    {
      key: 'date',
      label: '',
      visibility: true,
      cellType: 'custom',
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
    {
      key: 'time',
      label: '',
      visibility: true,
      cellType: 'custom',
      cellRenderer: () => (
        <GrayedCellRenderer
          bold
          withoutLeftPadding
          bgColor={Theme.colors.greyShades.grey5}
        >
          {t('table.header.labels.totalAverage')}
        </GrayedCellRenderer>
      ),
    },
    {
      key: 'score',
      label: '',
      visibility: true,
      width: '100px',
      cellType: 'custom',
      cellRenderer: (row) =>
        RatingScoreCellRenderer(
          row.averageValue,
          Theme.colors.greyShades.grey3,
          Theme.colors.primary.white
        ),
    },
    {
      key: 'note',
      label: '',
      visibility: true,
      cellType: 'custom',
      minWidth: customWidths.M,
      cellRenderer: getGreyedLightEmptyCellRenderer,
    },
  ];
};
