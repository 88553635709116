import { FC } from 'react';
import { IconButton, Icons } from 'team-hero-ui';
import { Moment } from 'moment';

import type { IBackdropItemClickCallbackProps } from '../TimelineBackdrop/TimelineBackdrop.types';
import {
  HoverClickOverlay,
  TimelineBackdropItemInnerStyled,
  TimelineBackdropItemStyled,
} from './TimelineBackdropItem.styled';

interface ITimelineBackdropItemProps {
  size: number;
  isStriped: boolean;
  groupId: string;
  startTime: Moment;
  backdropItemClickCallback?: (props: IBackdropItemClickCallbackProps) => void;
}

const TimelineBackdropItem: FC<ITimelineBackdropItemProps> = ({
  size,
  isStriped,
  groupId,
  startTime,
  backdropItemClickCallback,
}) => {
  return (
    <TimelineBackdropItemStyled $width={size}>
      <TimelineBackdropItemInnerStyled $isStriped={isStriped}>
        {backdropItemClickCallback && (
          <HoverClickOverlay>
            <IconButton
              size={'small'}
              onClick={() =>
                backdropItemClickCallback({
                  groupId,
                  clickTime: startTime,
                })
              }
            >
              <Icons.PlusIconV2 svgColor='currentColor' svgSize={24} />
            </IconButton>
          </HoverClickOverlay>
        )}
      </TimelineBackdropItemInnerStyled>
    </TimelineBackdropItemStyled>
  );
};

export default TimelineBackdropItem;
