import { ReactElement, useCallback, useMemo, useState } from 'react';

import type { IProjectTimesheet } from 'interfaces/ProjectTimesheet.interface';
import { useToggle } from 'hooks/useToggle.hook';
import { useLazyGetProjectTimesheetItemQuery } from 'services/teamHeroApi.service';
import { ClientApprovalModal } from 'components/Modals/ClientApprovalModal';

interface IUseClientApprovalModalReturn {
  clientApprovalModal: ReactElement | null;
  openClientApprovalModalWithIds: (ids: number[]) => void;
}

interface IClientApprovalModalProps {
  modalTitle?: string;
}

export const useClientApprovalModalByIds = ({
  modalTitle = '',
}: IClientApprovalModalProps): IUseClientApprovalModalReturn => {
  const [triggerTimesheet] = useLazyGetProjectTimesheetItemQuery();
  const { toggleValue: isOpen, on: open, off: close } = useToggle(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [timesheetsToUpdate, setTimesheetsToUpdate] = useState<
    IProjectTimesheet[]
  >([]);

  const getTimesheets = useCallback(
    async (ids: number[]) => {
      const timesheets = await Promise.all(
        ids.map((id) => triggerTimesheet({ id }).unwrap())
      );
      return timesheets;
    },
    [triggerTimesheet]
  );

  const openClientApprovalModalWithIds = useCallback(
    (ids: number[]) => {
      setIsLoading(true);
      getTimesheets(ids)
        .then((timesheets) => {
          setTimesheetsToUpdate(timesheets);
          open();
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [getTimesheets, open]
  );

  const closeCallback = useCallback(() => {
    setTimesheetsToUpdate([]);
    close();
  }, [close]);

  const clientApprovalModal = useMemo(() => {
    return (
      <ClientApprovalModal
        timesheets={timesheetsToUpdate}
        isLoading={isLoading}
        title={modalTitle}
        isOpen={isOpen}
        onClose={closeCallback}
      />
    );
  }, [timesheetsToUpdate, isLoading, modalTitle, isOpen, closeCallback]);

  return {
    clientApprovalModal,
    openClientApprovalModalWithIds,
  };
};
