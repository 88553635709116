import { useGetMessageConversationsQuery } from 'services/teamHeroApi.service';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import { UnreadMessagesConfig } from 'config/unreadMessages.config';

interface IUseGetUnreadMessagesCountReturn {
  unreadMessagesCount?: number;
}

const useGetUnreadMessagesCount = (
  skip = false
): IUseGetUnreadMessagesCountReturn => {
  const { currentUserContact } = useCurrentUserContact();
  const { data: unreadMessages } = useGetMessageConversationsQuery(
    {
      filters: [
        {
          key: 'unreadMessages',
          operator: 'AND',
          value: true,
        },
      ],
      participant: currentUserContact?.id,
      itemsPerPage: 0,
      page: 1,
    },
    {
      skip: !currentUserContact || skip,
      pollingInterval: UnreadMessagesConfig.pollingInterval,
    }
  );

  return {
    unreadMessagesCount: unreadMessages?.totalCount,
  };
};

export default useGetUnreadMessagesCount;
