import { FC } from 'react';

import type { IId } from 'interfaces/IId.interface';
import type { IRowSelectionProps } from 'components/TableView/hooks/useRowSelection.hook';
import { CheckboxCellRenderer } from 'components/cellRenderers/CheckboxCellRenderer/CheckboxCellRenderer';
import { MoreCellRenderer } from 'components/cellRenderers/MoreCellRenderer/MoreCellRenderer';

interface IMenuCellRendererProps<T> {
  row: T;
  rowSelectionProps: IRowSelectionProps;
}

export const MenuCellRenderer: FC<IMenuCellRendererProps<IId>> = ({
  row,
  rowSelectionProps: {
    areCheckboxesVisible,
    selectedRowsIds,
    onHandleRowSelection,
    setRowIdMenuOpen,
  },
}) => {
  const isSelected = selectedRowsIds.find((i) => i === row.id) !== undefined;

  if (areCheckboxesVisible) {
    return (
      <CheckboxCellRenderer
        row={row}
        isSelected={isSelected}
        onHandleRowSelection={onHandleRowSelection}
      />
    );
  }

  return <MoreCellRenderer row={row} setRowIdMenuOpen={setRowIdMenuOpen} />;
};
