import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { Button, Icons, Theme } from 'team-hero-ui';

import type { TTimelineSize } from 'components/Timeline/interfaces/timelineResolutions.interface';
import type { IWorkplannerSettingState } from 'components/Workplanner/Workplanner.interface';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import LocalizedDatePicker from 'components/LocalizedDatePicker';
import useTimelineRangeControls from 'hooks/useTimelineRangeControls.hook';
import TimelineSizeDropdown from 'components/Timeline/components/TimelineSizeDropdown';

interface ITimelineControlsProps {
  timelineSettings: IWorkplannerSettingState;
  setTimelineSettings: Dispatch<SetStateAction<IWorkplannerSettingState>>;
}

const timelineSizeItems: TTimelineSize[] = [
  '1day',
  '1week',
  '2weeks',
  '1month',
];

const TimelineControls: FC<ITimelineControlsProps> = ({
  timelineSettings,
  setTimelineSettings,
}) => {
  const { dayShortWithDate } = useDateFormat();

  /**
   * maximum day difference between the start and end date based on the timeline size and view mode
   */
  const maxDayDifference = useMemo(() => {
    // if calendar is shown, the max day difference is based on the timeline size
    if (timelineSettings.viewMode !== 'list') {
      switch (timelineSettings.timelineSize) {
        case '1day':
          return 0;
        case '1week':
          return 6;
        case '2weeks':
          return 13;
        case '1month':
          return 31;
        default:
          return 365;
      }
    }
    // else it is always 365 days
    return 365;
  }, [timelineSettings.timelineSize, timelineSettings.viewMode]);

  /**
   * @description minimum day difference based on the timeline size and view mode
   */
  const minDayDifference = useMemo(() => {
    // if calendar is shown, the min day difference is based on the timeline size
    if (timelineSettings.viewMode !== 'list') {
      switch (timelineSettings.timelineSize) {
        case '1day':
          return 0;
        case '1week':
          return 6;
        case '2weeks':
          return 13;
        case '1month':
          return 28;
        default:
          return 0;
      }
    }
    // else it is always 0 days
    return 0;
  }, [timelineSettings.timelineSize, timelineSettings.viewMode]);

  const {
    handleOnClickLeftArrow,
    handleOnClickRightArrow,
    handleSetTimelineSize,
    handleDateChange,
    handleOnClickToday,
  } = useTimelineRangeControls({
    setTimelineSettings,
    maxDayDifference,
    minDayDifference,
  });

  return (
    <>
      <Button onClick={handleOnClickToday} data-test-id='timeline-today-button'>
        <Icons.TargetIcon
          svgColor={Theme.colors.greyShades.grey2}
          svgSize={21}
        />
      </Button>
      {timelineSettings.viewMode !== 'list' && (
        <TimelineSizeDropdown
          value={timelineSettings.timelineSize}
          handleChange={handleSetTimelineSize}
          timelineSizeItems={timelineSizeItems}
        />
      )}
      <LocalizedDatePicker
        showArrows
        type='timeline'
        value={timelineSettings.startDate.toDate()}
        onChange={(value) => handleDateChange(value, 'start')}
        clickHandlerLeftArrow={() => handleOnClickLeftArrow('start')}
        clickHandlerRightArrow={() => handleOnClickRightArrow('start')}
        format={
          timelineSettings.timelineSize === '1day'
            ? dayShortWithDate
            : undefined
        }
      />
      {(timelineSettings.timelineSize !== '1day' ||
        timelineSettings.viewMode === 'list') && (
        <LocalizedDatePicker
          showArrows
          type='timeline'
          value={timelineSettings.endDate.toDate()}
          onChange={(value) => handleDateChange(value, 'end')}
          clickHandlerLeftArrow={() => handleOnClickLeftArrow('end')}
          clickHandlerRightArrow={() => handleOnClickRightArrow('end')}
        />
      )}
    </>
  );
};

export default TimelineControls;
