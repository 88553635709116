import { ReactElement } from 'react';
import { Skeleton } from 'team-hero-ui';

import type { TTableStyleValue } from 'components/Table/Table.types';
import type { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import type { IId } from 'interfaces/IId.interface';
import { StyledTableCell } from '../TableBody/TableBody.styled';
import { StatusCellRendererSkeleton } from 'components/cellRenderers/StatusCellRenderer/StatusCellRenderer.skeleton';
import { AvatarCellRenderer } from 'components/cellRenderers/AvatarCellRenderer/AvatarCellRenderer';
import ContactCellRenderer from 'components/cellRenderers/ContactCellRenderer';
import { EmptyCell } from 'components/cellRenderers/EmptyCellRenderer/EmptyCellRenderer';

interface ITableCellProps<T> {
  columnDefinition: IColumnDefinitionType<T>;
  styleType?: TTableStyleValue;
  index: number;
}

export const TableCellSkeleton = <T extends IId>({
  columnDefinition,
  styleType,
  index,
}: ITableCellProps<T>): ReactElement => {
  const getCell = () => {
    if (columnDefinition.skeletonType === 'status') {
      return <StatusCellRendererSkeleton />;
    }
    if (columnDefinition.skeletonType === 'empty') {
      return <EmptyCell contentText='' />;
    }
    if (columnDefinition.skeletonType === 'avatar') {
      return <AvatarCellRenderer showSkeleton />;
    }
    if (columnDefinition.skeletonType === 'contact') {
      return <ContactCellRenderer showSkeleton />;
    }

    // by default, return a text skeleton
    // random value between 45 and 70
    const randomWidth = Math.floor(Math.random() * 25) + 45;

    return <Skeleton.Text width={`${randomWidth}%`} />;
  };

  return (
    <StyledTableCell
      $isFirst={['beginSeparator', 'selectionStatus', 'status'].includes(
        columnDefinition.key
      )}
      $firstChildBorderRadius={['avatarFirstChild'].includes(
        columnDefinition.key
      )}
      key={`cell-${index}-${columnDefinition.key}`}
      $disabled={false}
      $styleType={styleType}
      data-component={'table-cell'}
      data-test-id={`table-cell-${columnDefinition.key}`}
    >
      {getCell()}
    </StyledTableCell>
  );
};
