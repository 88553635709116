import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

import { StyledTableCell } from 'components/Table/components/TableBody/TableBody.styled';

const { pxToRem } = Utils;

interface IStyledSummaryHeader {
  $gridColsWidth: string;
}

export const StyledSummaryHeader = styled.div<IStyledSummaryHeader>`
  position: relative;
  display: grid;
  grid-template-columns: ${({ $gridColsWidth }) => $gridColsWidth};
  font-size: ${pxToRem(14)};
  border-radius: ${pxToRem(5)};
  cursor: pointer;
  transition: all 0.2s ease-in;
  width: auto;
`;

export const StyledSummaryTableCell = styled(StyledTableCell)`
  background-color: ${Theme.colors.greyShades.grey5};
`;
