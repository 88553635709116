import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const TimelineItemInnerTopStyled = styled.div`
  ${flexFn('space-between', 'center', 'row')}
  padding: ${pxToRem([10, 10, 0, 10])};
  padding-right: ${pxToRem(50)};
  font-weight: 700;
  font-size: ${pxToRem(14)};
  gap: ${pxToRem(10)};
`;

export const TopTextStyled = styled.span`
  font-weight: 700;
  font-size: ${pxToRem(13)};
`;

export const TimelineItemInnerStyled = styled.div`
  color: inherit;
  height: 100%;
  ${flexFn('flex-start', 'stretch', 'column')}
`;

export const TimelineItemAvatarListWrapper = styled.div`
  flex: 1;
  gap: ${pxToRem(3)};
  ${flexFn('flex-start', 'center', 'row')}
`;

export const TimelineTimeDetailStyled = styled.div`
  ${flexFn('flex-start', 'center', 'row')}
  padding: ${pxToRem([0, 50, 0, 10])};
`;

export const TimelineTimeDetailTextStyled = styled.span`
  font-size: ${pxToRem(12)};
`;

export const TimelineItemInnerBottomStyled = styled.div`
  flex: 1;
  ${flexFn('space-between', 'center', 'row')}
  padding: ${pxToRem(10)};
  position: relative;
`;

export const TimelineItemAvatarMoreIndicatorStyled = styled.span`
  padding: ${pxToRem([0, 3])};
  font-size: ${pxToRem(11)};
`;

export const ConnectedIconWrapperStyled = styled.span`
  flex: 1;
  ${flexFn('flex-end', 'center', 'row')}
`;
