import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icons, Loader } from 'team-hero-ui';

import { preventEventWrapper } from 'helpers/events/preventEventWrapper.helper';
import { useGetProjectTimesheetItemQuery } from 'services/teamHeroApi.service';
import { RowLoadingWrapperStyled } from 'components/Table/components/RowAction/RowAction.styled';

interface ITimesheetAdditionalRowActions {
  rowIdMenuOpen: number | null;
  onClientApprovalClick: (id: number) => void;
}

export const TimesheetAdditionalRowActions: FC<
  ITimesheetAdditionalRowActions
> = ({ rowIdMenuOpen, onClientApprovalClick }) => {
  const { t } = useTranslation('general');

  const { data: timesheet, isLoading: isLoadingTimesheet } =
    useGetProjectTimesheetItemQuery(
      { id: rowIdMenuOpen ? rowIdMenuOpen : 0 },
      { skip: !rowIdMenuOpen }
    );

  if (!rowIdMenuOpen) {
    return null;
  }

  const handleToApproveClick = () => {
    onClientApprovalClick(rowIdMenuOpen);
  };

  if (!timesheet || isLoadingTimesheet) {
    return (
      <RowLoadingWrapperStyled>
        <Loader loaderSize='tiny' loaderType='static' />
      </RowLoadingWrapperStyled>
    );
  }

  return (
    <Button
      color='tableOverlay'
      onClick={(e) => preventEventWrapper(e, () => handleToApproveClick())}
      startIcon={<Icons.TimeTrackingSignedV1ExIcon svgSize={24} />}
    >
      {t('table.action.timesheetApprove')}
    </Button>
  );
};
