import { FC } from 'react';
import { Checkbox } from 'team-hero-ui';

import type { IId } from 'interfaces/IId.interface';
import { preventEventWrapper } from 'helpers/events/preventEventWrapper.helper';

interface ICheckboxCellRendererProps<T> {
  row: T;
  isSelected: boolean;
  onHandleRowSelection: (id: number) => void;
}

export const CheckboxCellRenderer: FC<ICheckboxCellRendererProps<IId>> = ({
  row,
  isSelected,
  onHandleRowSelection,
}) => {
  const onSelect = () => {
    onHandleRowSelection(row.id);
  };

  return (
    <Checkbox
      styleType='table'
      onChange={onSelect}
      onClick={preventEventWrapper}
      checked={isSelected}
    />
  );
};
