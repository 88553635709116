import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { IProjectTimesheetBody } from 'services/projectTimesheets/projectTimesheets.api.types';
import type { IApproveTimesheet } from 'components/Modals/ClientApprovalModal/ClientApprovalModal.types';
import type { IProjectTimesheet } from 'interfaces/ProjectTimesheet.interface';
import type { ISignatureFileResource } from 'interfaces/SignatureFile.interface';
import {
  teamHeroApi,
  usePostSignatureFileEntityItemMutation,
  usePostSignatureFileItemMutation,
  usePutProjectTimesheetsMutation,
} from 'services/teamHeroApi.service';
import useNotification from 'hooks/useNotification.hook';
import { useErrorHandler } from 'hooks/useDisplayApiError.hook';

interface IUseClientApprovalStrategiesReturn {
  updateTimesheetApprovalStrategy: (
    imageCanvas: HTMLCanvasElement,
    approveTimesheets: IApproveTimesheet[],
    initialTimesheets: IProjectTimesheet[]
  ) => Promise<void>;
}

export const useClientApprovalStrategies =
  (): IUseClientApprovalStrategiesReturn => {
    const { t } = useTranslation('general');
    const dispatch = useDispatch();
    const { handleError } = useErrorHandler();
    const { set: setNotification } = useNotification();
    const [postSignatureFileEntityItem] =
      usePostSignatureFileEntityItemMutation();
    const [postSignatureFileItem] = usePostSignatureFileItemMutation();
    const [putTimesheetItem] = usePutProjectTimesheetsMutation();

    const onSaveSuccess = useCallback(
      (message: string) => {
        setNotification({
          type: 'success',
          message: t(message),
        });
      },
      [setNotification, t]
    );

    const getTimesheetsToEdit = useCallback(
      (
        approveTimesheets: IApproveTimesheet[],
        initialTimesheets: IProjectTimesheet[]
      ): IApproveTimesheet[] =>
        approveTimesheets.filter((timesheet) =>
          initialTimesheets.some((initialTimesheet) => {
            if (timesheet.timesheetId !== initialTimesheet.id) {
              return false;
            }

            if (
              timesheet.isExternallyApproved !==
              initialTimesheet.isExternallyApproved
            ) {
              return true;
            }

            if (timesheet.note !== initialTimesheet.note) {
              return true;
            }

            return false;
          })
        ),
      []
    );

    const createSignature = async (
      imageCanvas: HTMLCanvasElement
    ): Promise<ISignatureFileResource> => {
      const response = await postSignatureFileEntityItem().unwrap();
      imageCanvas.toBlob((blob) => {
        const body = new FormData();
        body.append('file', blob as Blob);

        postSignatureFileItem({
          id: response.id,
          body,
        });
      });
      return response;
    };

    const updateTimesheetApprovalStrategy = async (
      imageCanvas: HTMLCanvasElement,
      approveTimesheets: IApproveTimesheet[],
      initialTimesheets: IProjectTimesheet[]
    ): Promise<void> => {
      const signatureResponse = await createSignature(imageCanvas);
      const timesheetsToUpdate = getTimesheetsToEdit(
        approveTimesheets,
        initialTimesheets
      );

      try {
        await Promise.all(
          timesheetsToUpdate.map((timesheet) => {
            const body: IProjectTimesheetBody = {
              note: timesheet.note,
              approvalSignature: timesheet.isApproved
                ? signatureResponse['@id']
                : null,
              isExternallyApproved: timesheet.isApproved,
            };

            return putTimesheetItem({
              body,
              id: timesheet.timesheetId,
            }).unwrap();
          })
        );
        onSaveSuccess(t('action.clientApprovalUpdated'));
      } catch (error) {
        handleError(error);
      } finally {
        dispatch(teamHeroApi.util.invalidateTags(['ProjectTimesheet']));
      }
    };

    return {
      updateTimesheetApprovalStrategy,
    };
  };
