import { FC } from 'react';

import LanguageInitializeProvider from 'providers/LanguageInitializeProvider';
import UserSnapProvider from 'components/UserSnapProvider/UserSnapProvider';
import Notification from 'components/Notification';
import SentryProvider from '../SentryProvider/SentryProvider';
import SearchPopupProvider from 'components/SearchPopup/SearchPopupProvider';
import NativeAppUrlListener from 'routes/NativeAppUrlListener';
import NativeStatusBarProvider from 'components/NativeStatusBarProvider';
import NativePushNotificationsProvider from 'components/NativePushNotificationsProvider';

const GlobalComponentsProvider: FC = () => {
  return (
    <>
      <NativeAppUrlListener />
      <NativeStatusBarProvider />
      <NativePushNotificationsProvider />
      <SentryProvider />
      <UserSnapProvider />
      <Notification />
      <LanguageInitializeProvider />
      <SearchPopupProvider />
    </>
  );
};

export default GlobalComponentsProvider;
