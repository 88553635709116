import { FC } from 'react';

import type { IContactEmbed } from 'interfaces/Contact.interface';
import type { TSourceValue } from 'helpers/file/getImageUrl.helper';
import Avatar from 'components/Avatar';
import { CellAvatarContainerStyled } from './AvatarCellRenderer.styled';

interface IAvatarCellRendererProp {
  avatar?: string | null;
  firstName?: string;
  lastName?: string;
  id?: number;
  canOpenQuickView?: boolean;
  contact?: IContactEmbed;
  sourceType?: TSourceValue;
  showSkeleton?: boolean;
}

export const AvatarCellRenderer: FC<IAvatarCellRendererProp> = ({
  avatar,
  firstName,
  lastName,
  contact,
  sourceType = 'file',
  showSkeleton = false,
}) => {
  return (
    <CellAvatarContainerStyled>
      <Avatar
        firstName={firstName}
        lastName={lastName}
        sourceType={sourceType}
        images={avatar || ''}
        embeddedContact={contact}
        showSkeleton={showSkeleton}
      />
    </CellAvatarContainerStyled>
  );
};
