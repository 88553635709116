import moment from 'moment';
import { TWorkplannerCardStatus } from 'team-hero-ui';

import type {
  IProjectShift,
  IProjectShiftWorkplanner,
} from 'interfaces/ProjectShift.interface';
import type {
  ITimelineGroup,
  ITimelineItem,
} from 'components/Timeline/interfaces/timeline.interface';
import type { TShiftGroupKey } from 'components/Timeline/Timeline.type';
import { getContactIriFromIriOrContact } from 'helpers/contact/getContactId.helper';
import { getCombinedShiftStatus } from 'helpers/shifts/shiftStatusCount.helper';
import {
  sumShiftsByMultipleCriteria,
  sumWorkplannerShiftsByMultipleCriteria,
} from 'helpers/shifts/sumShiftsByMultipleCriteria.helper';
import { onlyUnique } from 'helpers/array/arrayOperations.helper';

export interface IContactIriWithShiftStatus {
  contact: string; // iri-string
  shiftStatus: TWorkplannerCardStatus;
}

export const getGroupsFromShiftsByKey = (
  timelineShifts: IProjectShiftWorkplanner[],
  key: TShiftGroupKey
): ITimelineGroup[] => {
  const keyElements = timelineShifts
    .map((shift) => shift[key])
    .filter(onlyUnique);

  return keyElements.map((keyElement) => {
    return {
      id: keyElement,
      label: keyElement,
    };
  });
};

export const getGroupsFromShiftsByStartDate = (
  timelineShifts: IProjectShiftWorkplanner[]
): string[] => {
  const keyElements = timelineShifts
    .map((shift) => moment(shift.start).format('DD-MM'))
    .filter(onlyUnique);

  return keyElements.map((keyElement) => {
    return keyElement;
  });
};

export const getItemsFromShiftsWithGroupByKey = (
  timelineShifts: IProjectShiftWorkplanner[],
  key: TShiftGroupKey
): ITimelineItem<IProjectShiftWorkplanner>[] => {
  const timelineItems: ITimelineItem<IProjectShiftWorkplanner>[] =
    timelineShifts.map((shift) => {
      return {
        id: shift.id,
        start: moment(shift.start),
        end: moment(shift.end),
        group: shift[key],
        item: shift,
      };
    });

  timelineItems.sort((a, b) => a.start.diff(b.start));

  return timelineItems;
};

export const getSummedItemsFromWorkplannerShiftsWithGroupByKey = (
  timelineShifts: IProjectShiftWorkplanner[],
  key: TShiftGroupKey
): ITimelineItem<IProjectShiftWorkplanner[]>[] => {
  const summedShifts = sumWorkplannerShiftsByMultipleCriteria(timelineShifts, [
    'position',
    'start',
    'end',
    'payment',
    'area',
    'linkedPosition',
  ]);

  const timelineItems: ITimelineItem<IProjectShiftWorkplanner[]>[] =
    Object.entries(summedShifts).map(([collectionKey, shiftCollection]) => ({
      id: collectionKey,
      start: moment(shiftCollection[0].start),
      end: moment(shiftCollection[0].end),
      group: shiftCollection[0][key],
      item: shiftCollection,
    }));

  timelineItems.sort((a, b) => a.start.diff(b.start));

  return timelineItems;
};

export const getSummedItemsFromShiftsWithGroupByKey = (
  timelineShifts: IProjectShift[],
  key: TShiftGroupKey
): ITimelineItem<IProjectShift[]>[] => {
  const summedShifts = sumShiftsByMultipleCriteria(timelineShifts, [
    'position',
    'start',
    'end',
    'payment',
    'area',
    'linkedPosition',
  ]);

  const timelineItems: ITimelineItem<IProjectShift[]>[] = Object.entries(
    summedShifts
  ).map(([collectionKey, shiftCollection]) => ({
    id: collectionKey,
    start: moment(shiftCollection[0].start),
    end: moment(shiftCollection[0].end),
    group: shiftCollection[0][key],
    item: shiftCollection,
  }));

  timelineItems.sort((a, b) => a.start.diff(b.start));
  return timelineItems;
};

export const getGroupedShiftsByKey = (
  timelineShifts: IProjectShiftWorkplanner[],
  groupKey: TShiftGroupKey
): {
  groupedShifts: { [key: string]: IProjectShiftWorkplanner[] };
  groups: ITimelineGroup[];
} => {
  const groups = getGroupsFromShiftsByKey(timelineShifts, groupKey);

  const groupedShifts: { [key: string]: IProjectShiftWorkplanner[] } = {};

  groups.forEach((keyElement) => {
    groupedShifts[keyElement.id] = timelineShifts.filter(
      (timelineShift) => timelineShift[groupKey] === keyElement.id
    );
  });

  return {
    groupedShifts,
    groups,
  };
};

export const getGroupedShiftsByStartDate = (
  timelineShifts: IProjectShiftWorkplanner[]
): {
  groupedShifts: { [key: string]: IProjectShiftWorkplanner[] };
  groups: string[];
} => {
  const groups = getGroupsFromShiftsByStartDate(timelineShifts);

  const groupedShifts: { [key: string]: IProjectShiftWorkplanner[] } = {};

  groups.forEach((keyElement) => {
    groupedShifts[keyElement] = timelineShifts.filter(
      (timelineShift) =>
        moment(timelineShift.start).format('DD-MM') === keyElement
    );
  });

  return {
    groupedShifts,
    groups,
  };
};

export const getItemsAndGroupsFromShiftsByKey = (
  timelineShifts: IProjectShiftWorkplanner[],
  key: TShiftGroupKey
): {
  groups: ITimelineGroup[];
  items: ITimelineItem<IProjectShiftWorkplanner>[];
} => {
  return {
    groups: getGroupsFromShiftsByKey(timelineShifts, key),
    items: getItemsFromShiftsWithGroupByKey(timelineShifts, key),
  };
};

export const getSummedItemsAndGroupsFromShiftsByKey = (
  timelineShifts: IProjectShiftWorkplanner[],
  key: TShiftGroupKey
): {
  groups: ITimelineGroup[];
  items: ITimelineItem<IProjectShiftWorkplanner[]>[];
} => {
  return {
    groups: getGroupsFromShiftsByKey(timelineShifts, key),
    items: getSummedItemsFromWorkplannerShiftsWithGroupByKey(
      timelineShifts,
      key
    ),
  };
};

// returns iris of contacts that are assigned to shifts
export const getContactsFromShifts = (
  shifts: IProjectShiftWorkplanner[]
): string[] => {
  return shifts.reduce<string[]>((acc, shift) => {
    if (
      shift.contact &&
      acc.filter((accumulatedContact) => accumulatedContact === shift.contact)
        .length === 0
    ) {
      acc.push(getContactIriFromIriOrContact(shift.contact));
    }
    return acc;
  }, []);
};

const getCombinedStatusFromShiftsByContact = (
  shifts: IProjectShiftWorkplanner[],
  contactIri: string
): TWorkplannerCardStatus => {
  const filteredShifts = shifts.filter((shift) => shift.contact === contactIri);
  return getCombinedShiftStatus(filteredShifts);
};

export const sortPersonsByShiftStatus = (
  personsWithShiftStatus: IContactIriWithShiftStatus[]
): IContactIriWithShiftStatus[] => {
  return [...personsWithShiftStatus].sort((shift1, shift2) => {
    if (shift1.shiftStatus === shift2.shiftStatus) {
      return 0;
    }
    if (shift1.shiftStatus === 'open') {
      return -1;
    }
    if (shift1.shiftStatus === 'planned' && shift2.shiftStatus !== 'open') {
      return -1;
    } else {
      return 1;
    }
  });
};

export const getContactsFromShiftsWithStatus = (
  shifts: IProjectShiftWorkplanner[]
): IContactIriWithShiftStatus[] => {
  const personsWithShiftStatus = shifts.reduce<IContactIriWithShiftStatus[]>(
    (acc, shift) => {
      if (
        shift.contact &&
        acc.filter(
          (accumulatedContact) => accumulatedContact.contact === shift.contact
        ).length === 0
      ) {
        acc.push({
          contact: shift.contact,
          shiftStatus: getCombinedStatusFromShiftsByContact(
            shifts,
            shift.contact
          ),
        });
      }
      return acc;
    },
    []
  );

  return sortPersonsByShiftStatus(personsWithShiftStatus);
};

export const sortShiftsByStatus = (
  shifts: IProjectShiftWorkplanner[]
): IProjectShiftWorkplanner[] => {
  return [...shifts].sort((shift1, shift2) => {
    if (shift1.status === shift2.status) {
      return 0;
    }
    if (shift1.status === 'open') {
      return -1;
    }
    if (shift1.status === 'planned' && shift2.status !== 'open') {
      return -1;
    } else {
      return 1;
    }
  });
};

export const getShiftsWithContactsFromShifts = (
  shifts: IProjectShiftWorkplanner[]
): IProjectShiftWorkplanner[] => {
  return shifts.reduce<IProjectShiftWorkplanner[]>((acc, shift) => {
    if (shift.contact) {
      acc.push(shift);
    }
    return acc;
  }, []);
};

export const getShiftsGroupedByAssignedStatus = (
  shifts: IProjectShiftWorkplanner[]
): {
  openShifts: IProjectShiftWorkplanner[];
  assignedShifts: IProjectShiftWorkplanner[];
} => {
  const openShifts = shifts.filter((shift) => shift.status === 'open');
  const assignedShifts = shifts.filter((shift) =>
    ['confirmed', 'planned', 'booked', 'deactivated'].includes(shift.status)
  );

  return {
    openShifts,
    assignedShifts,
  };
};
