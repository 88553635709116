import { FC } from 'react';

import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import type { IWorkplannerSettingState } from 'components/Workplanner/Workplanner.interface';
import WorkplannerTimelineView from 'components/Workplanner/components/WorkplannerTimelineView';
import WorkplannerTable from 'components/Workplanner/components/WorkplannerTable';

interface IWorkplannerBodyProps {
  workplannerSettings: IWorkplannerSettingState;
  mission: IProjectMission;
}

const WorkplannerBody: FC<IWorkplannerBodyProps> = ({
  workplannerSettings,
  mission,
}) => {
  switch (workplannerSettings.viewMode) {
    case 'timeline':
      return (
        <WorkplannerTimelineView
          timelineSize={workplannerSettings.timelineSize}
          timelineStart={workplannerSettings.startDate}
          timelineEnd={workplannerSettings.endDate}
          mission={mission}
        />
      );
    case 'list':
      return (
        <WorkplannerTable
          mission={mission}
          workplannerSettings={workplannerSettings}
        />
      );
    default:
      return <div>this mode is not enabled yet</div>;
  }
};

export default WorkplannerBody;
