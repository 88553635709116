import styled from 'styled-components';
import { Typography, Utils, theme } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const TitleStyled = styled(Typography.H1)`
  color: ${theme.colors.greyShades.grey1};
  padding: ${pxToRem([10, 0])};
  text-align: center;
  white-space: break-spaces;
  width: 100%;
`;

export const TypographyStyled = styled(Typography.H3)`
  color: ${theme.colors.greyShades.grey3};
  text-align: center;
  font-weight: 100;
  white-space: break-spaces;
  width: 100%;
`;

export const IconsWrapperStyled = styled.div`
  margin: auto;
  max-width: 100%;
  & svg {
    max-width: 100%;
    height: auto;
  }
`;

export const NoResultGraphicWrapper = styled.div`
  ${flexFn('center', 'center', 'column')};
  text-align: center;
  width: 100%;
  gap: ${pxToRem(20)};
  padding: ${pxToRem([20, 40])};
  max-width: 100%;
  height: 100%;
`;
