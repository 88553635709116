import type { IContact, IContactEmbed } from 'interfaces/Contact.interface';
import { checkEmbedIsContact } from 'helpers/embed/embedTypeCheck.helper';

export const getContactIdFromIriOrContact = (
  contact?: string | IContact | IContactEmbed | number | null
): number | undefined => {
  if (!contact) {
    return undefined;
  }
  if (typeof contact === 'number') {
    return contact;
  }
  if (checkEmbedIsContact(contact)) {
    return contact.id;
  }
  const id = parseInt(contact.replace('/api/contacts/', ''), 10);
  if (isNaN(id)) {
    return undefined;
  }
  return id;
};

export const getContactIriFromIriOrContact = (
  contact: string | IContact | IContactEmbed
): string => {
  if (checkEmbedIsContact(contact)) {
    return contact['@id'];
  }
  return contact;
};
