import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Icons } from 'team-hero-ui';

import { IBulkEditRowProps } from './BulkEditRow.type';
import {
  ActionButtonStyled,
  bulkEditIconSize,
  BulkEditRowStyled,
} from './BulkEditRow.styled';

export const BulkEditRow: FC<IBulkEditRowProps> = ({
  onSelectAll,
  onDeselectAll,
  onDelete,
  onCancel,
  rowPosition,
  additionalBulkActions,
}) => {
  const { t } = useTranslation('general');

  const buttonColorValue = 'transparentBlack';

  return (
    <BulkEditRowStyled $position={rowPosition}>
      <ActionButtonStyled color={buttonColorValue} onClick={onSelectAll}>
        {t('general:table.addons.bulkEdit.selectAll')}
      </ActionButtonStyled>
      <ActionButtonStyled color={buttonColorValue} onClick={onDeselectAll}>
        {t('general:table.addons.bulkEdit.deselectAll')}
      </ActionButtonStyled>
      <ActionButtonStyled
        onClick={onCancel}
        color={buttonColorValue}
        startIcon={
          <Icons.CloseIcon svgSize={bulkEditIconSize} svgColor='currentColor' />
        }
      >
        {t('general:table.addons.bulkEdit.cancel')}
      </ActionButtonStyled>
      {onDelete && (
        <IconButton onClick={onDelete}>
          <Icons.DeleteIcon
            svgSize={bulkEditIconSize}
            svgColor='currentColor'
          />
        </IconButton>
      )}
      {additionalBulkActions}
    </BulkEditRowStyled>
  );
};
