import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  BottomNavigation,
  Icons,
  Utils,
  device,
  Loader,
  Theme,
} from 'team-hero-ui';

import DesktopNavigationLink from 'components/Layout/components/DesktopNavigationLink';
import MobileNavigationLink from 'components/Layout/components/MobileNavigationLink';
import LayoutError from 'components/LayoutError';
import {
  LayoutInnerStyled,
  LayoutNavigationStyled,
  LayoutOuterStyled,
} from 'components/Layout/Layout.styled';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import Avatar from 'components/Avatar';
import useGetUnreadMessagesCount from 'hooks/useGetUnreadMessagesCount.hook';
import useGetUnreadNotificationCount from 'hooks/useGetUnreadNotificationCount.hook';

const svgSize = 28;
const mobileSvgSize = 28;
const mobileNavigationHeight = 64;

const RetireLoginLayout: FC = () => {
  const { t, ready } = useTranslation([
    'general',
    'errors',
    'logs',
    'form',
    'warnings',
  ]);

  const { useMediaQuery } = Utils;

  const { currentUserContact } = useCurrentUserContact();

  const { unreadMessagesCount } = useGetUnreadMessagesCount();
  const { newNotificationsCount } = useGetUnreadNotificationCount();

  const isSmScreenSize = useMediaQuery(device.sm);

  const UserAvatar = () => (
    <Avatar
      sourceType='file'
      size={svgSize}
      images={currentUserContact?.avatar || ''}
      firstName={currentUserContact?.firstName}
      lastName={currentUserContact?.lastName}
      thumbnailSize={'small'}
    />
  );

  return (
    <LayoutOuterStyled
      $isTablet={isSmScreenSize}
      $mobileNavigationHeight={mobileNavigationHeight}
    >
      {isSmScreenSize && (
        <LayoutNavigationStyled>
          <AppBar.Bar>
            <AppBar.Section>
              <DesktopNavigationLink
                to={'/'}
                dataTestId='dashboard-link'
                label={t('general:navigation.label.dashboard')}
                matchEntirePath
              >
                <Icons.HomeIcon svgSize={30} />
              </DesktopNavigationLink>
              <DesktopNavigationLink
                to={'/work'}
                dataTestId='work-link'
                label={t('general:navigation.label.work')}
                disabled
              >
                <Icons.WorkIcon svgSize={svgSize} />
              </DesktopNavigationLink>
              <DesktopNavigationLink
                to={'/messages'}
                dataTestId='messages-link'
                badgeCount={unreadMessagesCount}
                label={t('general:navigation.label.messages')}
              >
                <Icons.CommunicationIcon svgSize={svgSize} />
              </DesktopNavigationLink>
              <DesktopNavigationLink
                to={'/notifications'}
                badgeCount={newNotificationsCount}
                dataTestId='notifications-link'
                label={t('general:navigation.label.notifications')}
              >
                <Icons.AlertIcon svgSize={svgSize} />
              </DesktopNavigationLink>
            </AppBar.Section>
            <AppBar.Section>
              <DesktopNavigationLink
                to={'/profile'}
                dataTestId='profile-link'
                label={t('general:navigation.label.profile')}
              >
                <UserAvatar />
              </DesktopNavigationLink>
              <DesktopNavigationLink
                to={'/logout'}
                dataTestId='logout-link'
                label={t('general:navigation.label.logout')}
              >
                <Icons.LogoutIcon
                  svgSize={28}
                  svgColor={Theme.colors.greyShades.grey3}
                />
              </DesktopNavigationLink>
            </AppBar.Section>
          </AppBar.Bar>
        </LayoutNavigationStyled>
      )}
      <LayoutInnerStyled>
        {ready ? (
          <ErrorBoundary
            fallback={({ eventId, resetError }) => (
              <LayoutError
                eventId={eventId}
                displayReportButton
                resetError={resetError}
              />
            )}
          >
            <Outlet />
          </ErrorBoundary>
        ) : (
          <Loader />
        )}
      </LayoutInnerStyled>
      {!isSmScreenSize && (
        <BottomNavigation.Navigation>
          <MobileNavigationLink
            height={mobileNavigationHeight}
            to={'/'}
            dataTestId='dashboard-link'
            label={t('general:navigation.label.dashboard')}
            matchEntirePath
          >
            <Icons.HomeIcon svgSize={mobileSvgSize} />
          </MobileNavigationLink>
          <MobileNavigationLink
            height={mobileNavigationHeight}
            to={'/work'}
            dataTestId='work-link'
            label={t('general:navigation.label.work')}
            disabled
          >
            <Icons.WorkIcon svgSize={mobileSvgSize} />
          </MobileNavigationLink>
          <MobileNavigationLink
            height={mobileNavigationHeight}
            to={'/messages'}
            dataTestId='messages-link'
            badgeCount={unreadMessagesCount}
            label={t('general:navigation.label.messages')}
          >
            <Icons.CommunicationIcon
              svgSize={mobileSvgSize}
              svgColor='inherit'
            />
          </MobileNavigationLink>
          <MobileNavigationLink
            height={mobileNavigationHeight}
            to={'/notifications'}
            badgeCount={newNotificationsCount}
            dataTestId='notifications-link'
            label={t('general:navigation.label.notifications')}
          >
            <Icons.AlertIcon svgSize={mobileSvgSize} />
          </MobileNavigationLink>
          <MobileNavigationLink
            to='/profile'
            height={mobileNavigationHeight}
            label={t('general:navigation.label.profile')}
          >
            <UserAvatar />
          </MobileNavigationLink>
        </BottomNavigation.Navigation>
      )}
    </LayoutOuterStyled>
  );
};

export default RetireLoginLayout;
