import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

import { IconWrapperStyled } from '../Common.styled';

const { pxToRem } = Utils;

export const MoreIconWrapperStyled = styled(IconWrapperStyled)`
  flex: 1;
  width: 100%;
  height: 100%;
  padding-left: ${pxToRem(5)};
`;
