import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons, Theme } from 'team-hero-ui';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import {
  ContainerStyled,
  ContentWrapperStyled,
  LogoWrapperStyled,
  DescriptionStyled,
  ErrorH1,
  ErrorH2,
  ItemStyled,
  LogoItemStyled,
  ButtonStyled,
  ButtonWrapperStyled,
  IconButtonStyled,
} from './LayoutNoPermission.styled';
import { useToggle } from 'hooks/useToggle.hook';

interface ILayoutNoPermissionProps {
  headline?: string;
  subHeadline?: string;
  description?: string;
  resetError?: () => void;
  resetKey?: string;
  eventId?: string;
  closeHandler?: () => void;
}

const LayoutNoPermission: FC<ILayoutNoPermissionProps> = ({
  headline,
  subHeadline,
  description,
  resetError,
  resetKey = '/',
  eventId,
  closeHandler,
}) => {
  // save current resetKey in ref for comparison in useEffect
  const prevKeyRef = useRef<string>(resetKey);

  // if the resetKey changes, reset the error
  useEffect(() => {
    if (prevKeyRef.current !== resetKey) {
      resetError?.();
    }
    prevKeyRef.current = resetKey;
  }, [resetError, resetKey]);

  const { t } = useTranslation('errors');
  const { toggleValue: reportPressed, on: reportPressedOn } = useToggle(false);
  const SentryClient = Sentry.getClient();

  const navigate = useNavigate();

  return (
    <ContainerStyled>
      {!!closeHandler && (
        <IconButtonStyled onClick={closeHandler}>
          <Icons.CloseIcon
            svgSize={30}
            svgColor={Theme.colors.greyShades.grey2}
          />
        </IconButtonStyled>
      )}

      <ItemStyled>
        <LogoItemStyled>
          <LogoWrapperStyled>
            <Icons.GraphicNoPermission
              svgSize={400}
              svgColor={Theme.colors.greyShades.grey2}
            />
          </LogoWrapperStyled>
        </LogoItemStyled>
      </ItemStyled>
      <ItemStyled>
        <ContentWrapperStyled>
          <ErrorH1>{headline || t('noPermissionPage.headline')}</ErrorH1>
          <ErrorH2>{subHeadline || t('noPermissionPage.subHeadline')}</ErrorH2>
          <DescriptionStyled>
            {description || t('noPermissionPage.description')}
          </DescriptionStyled>
          <ButtonWrapperStyled>
            <ButtonStyled
              color='primary'
              onClick={() => {
                navigate(`/messages/new-message`);
              }}
              startIcon={<Icons.MessageUniversalIcon svgSize={30} />}
            >
              {t('noPermissionPage.inAppMessage')}
            </ButtonStyled>
            {!!SentryClient && !!eventId && (
              <ButtonStyled
                color='primary'
                onClick={() => {
                  Sentry.showReportDialog({ eventId });
                  reportPressedOn();
                }}
                startIcon={<Icons.SendIcon svgSize={22} />}
                disabled={!!reportPressed}
              >
                {t('errorPage.reportError')}
              </ButtonStyled>
            )}
          </ButtonWrapperStyled>
        </ContentWrapperStyled>
      </ItemStyled>
    </ContainerStyled>
  );
};

export default LayoutNoPermission;
