import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const AvatarTooltipWrapper = styled.div`
  ${flexFn('flex-end', 'start', 'column')};
  gap: ${pxToRem(3)};
`;

export const TooltipLine = styled.div`
  flex: 1;
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(3)};
`;
