import { ReactElement, useCallback, useMemo } from 'react';

import type { IId } from 'interfaces/IId.interface';
import type { ITableProps } from 'components/Table/Table.types';
import { getGridColsWidth } from 'components/Table/components/util';
import { TableCell } from '../TableCell/TableCell';
import {
  StyledTableBodyInnerRow,
  StyledTableBodyRow,
} from './TableBody.styled';
import { TableCellSkeleton } from '../TableCell/TableCell.skeleton';

export interface ITableRowsProps<T extends IId>
  extends Omit<ITableProps<T>, 'sorting'> {}

export const TableBody = <T extends IId>({
  data,
  columns,
  rowIdMenuOpen,
  isMin,
  selectedId,
  styleType,
  onRowClick,
  editRowComponent,
  rowCollapsibleContentRenderer,
  bottomRowRenderer,
  topRowRenderer,
  isLoading,
  numSkeletonRows,
}: ITableRowsProps<T>): ReactElement => {
  const getRowEdit = useCallback(
    (id: number) => {
      if (rowIdMenuOpen === id && editRowComponent) {
        const EditRowComponent = editRowComponent;
        return <EditRowComponent />;
      }
      return null;
    },
    [editRowComponent, rowIdMenuOpen]
  );

  const getCollapsibleRow = useCallback(
    (rowId: number) => {
      return rowCollapsibleContentRenderer
        ? rowCollapsibleContentRenderer(rowId, columns)
        : null;
    },
    [columns, rowCollapsibleContentRenderer]
  );

  const skeletonRender = useMemo(() => {
    return (
      <>
        {[...Array(numSkeletonRows || 20).keys()].map((value) => {
          return (
            <StyledTableBodyRow
              key={`row-${value}`}
              $isMin={isMin}
              $styleType={styleType}
              data-component={'table-body-row-skeleton'}
              data-test-id={`table-body-row-skeleton-${value}`}
            >
              <StyledTableBodyInnerRow
                $gridColsWidth={getGridColsWidth(columns)}
              >
                {columns.map((column) => {
                  return column.visibility ? (
                    <TableCellSkeleton
                      columnDefinition={column}
                      styleType={styleType}
                      key={`cell-${value}-${column.key}`}
                      index={value}
                    />
                  ) : null;
                })}
              </StyledTableBodyInnerRow>
            </StyledTableBodyRow>
          );
        })}
      </>
    );
  }, [columns, isMin, numSkeletonRows, styleType]);

  return (
    <div data-component='table-body'>
      {topRowRenderer}
      {isLoading && skeletonRender}

      {data.map((row, index) => {
        const onClick = () =>
          !rowIdMenuOpen && onRowClick && onRowClick(row.id);

        return (
          <StyledTableBodyRow
            key={`row-${row.id}`}
            onClick={onClick}
            $isMin={isMin}
            $styleType={styleType}
            data-component={'table-body-row'}
            data-test-id={`table-body-row-${index}`}
          >
            <StyledTableBodyInnerRow $gridColsWidth={getGridColsWidth(columns)}>
              {columns.map((column) => {
                return column.visibility ? (
                  <TableCell
                    onRowClick={onRowClick}
                    columnDefinition={column}
                    rowItem={row}
                    isMin={isMin}
                    selectedId={selectedId}
                    styleType={styleType}
                    key={`cell-${row.id}-${column.key}`}
                  />
                ) : null;
              })}
              {getRowEdit(row.id)}
            </StyledTableBodyInnerRow>
            {getCollapsibleRow(row.id)}
          </StyledTableBodyRow>
        );
      })}
      {bottomRowRenderer}
    </div>
  );
};
