import { FC } from 'react';
import { Tooltip } from 'team-hero-ui';

import type { IProjectShiftWorkplanner } from 'interfaces/ProjectShift.interface';
import type { IShiftsContactsTooltipProps } from './ShiftsContactsTooltip.types';
import AvatarStatusRenderer from 'components/Workplanner/components/AvatarStatusRenderer';
import {
  getShiftsWithContactsFromShifts,
  sortShiftsByStatus,
} from 'components/Timeline/helper/shiftTimeline.helper';
import {
  AvatarTooltipWrapper,
  TooltipLine,
} from './ShiftsContactsTooltip.styled';

const ShiftsContactsTooltip: FC<IShiftsContactsTooltipProps> = ({
  children,
  shifts,
  renderNameInTooltip = true,
}) => {
  const shiftsWithContacts: IProjectShiftWorkplanner[] = sortShiftsByStatus(
    getShiftsWithContactsFromShifts(shifts)
  );

  if (shiftsWithContacts.length === 0) {
    return null;
  }

  return (
    <Tooltip
      styleType={'right'}
      title={
        <AvatarTooltipWrapper>
          {shiftsWithContacts.map((shift) => (
            <TooltipLine key={shift.id}>
              <AvatarStatusRenderer
                shift={shift}
                displayName={!renderNameInTooltip}
                showNameWithoutHover={renderNameInTooltip}
              />
            </TooltipLine>
          ))}
        </AvatarTooltipWrapper>
      }
      displayArrow
    >
      {children}
    </Tooltip>
  );
};

export default ShiftsContactsTooltip;
