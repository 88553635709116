import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Button, Icons, Theme } from 'team-hero-ui';

import type { IRangeViewSettingState } from 'components/RangeTimelineControls/RangeTimelineControls.types';
import type { TTimelineSize } from 'components/Timeline/interfaces/timelineResolutions.interface';
import LocalizedDatePicker from 'components/LocalizedDatePicker';
import useTimelineRangeControls from 'hooks/useTimelineRangeControls.hook';
import TimelineSizeDropdown from 'components/Timeline/components/TimelineSizeDropdown';
import { RangeTimelineControlsStyled } from './RangeTimelineControls.styled';

interface IRangeTimelineControlsProps<T> {
  timelineSettings: IRangeViewSettingState<T>;
  setTimelineSettings: Dispatch<SetStateAction<IRangeViewSettingState<T>>>;
  timelineSizeItems?: TTimelineSize[];
  defaultMaxDayDifference?: number;
}

const RangeTimelineControls = <T,>({
  timelineSettings,
  setTimelineSettings,
  timelineSizeItems = ['1week', '2weeks', '1month'],
  defaultMaxDayDifference = 365,
}: IRangeTimelineControlsProps<T>): JSX.Element => {
  /**
   * maximum day difference between the start and end date based on the timeline size and view mode
   */
  const maxDayDifference = useMemo(() => {
    // if calendar is shown, the max day difference is based on the timeline size
    if (timelineSettings.viewMode === 'calendar') {
      switch (timelineSettings.timelineSize) {
        case '1week':
          return 6;
        case '2weeks':
          return 13;
        case '1month':
          return 31;
        default:
          return defaultMaxDayDifference;
      }
    }
    // else it is always the default max day difference
    return defaultMaxDayDifference;
  }, [
    defaultMaxDayDifference,
    timelineSettings.timelineSize,
    timelineSettings.viewMode,
  ]);

  /**
   * @description minimum day difference based on the timeline size and view mode
   */
  const minDayDifference = useMemo(() => {
    // if calendar is shown, the min day difference is based on the timeline size
    if (timelineSettings.viewMode === 'calendar') {
      switch (timelineSettings.timelineSize) {
        case '1week':
          return 6;
        case '2weeks':
          return 13;
        case '1month':
          return 28;
        default:
          return 0;
      }
    }
    // else it is always 0 days
    return 0;
  }, [timelineSettings.timelineSize, timelineSettings.viewMode]);

  // if the timeline size is not in the list of available timeline sizes, reset to month or first item in the list
  useEffect(() => {
    if (timelineSizeItems?.includes(timelineSettings.timelineSize)) {
      return;
    }
    const updatedTimelineSizeItem: TTimelineSize = timelineSizeItems?.includes(
      '1month'
    )
      ? '1month'
      : timelineSizeItems[0];

    setTimelineSettings((prevState) => ({
      ...prevState,
      timelineSize: updatedTimelineSizeItem,
    }));
  }, [setTimelineSettings, timelineSettings.timelineSize, timelineSizeItems]);

  const {
    handleOnClickLeftArrow,
    handleOnClickRightArrow,
    handleDateChange,
    handleOnClickToday,
    handleSetTimelineSize,
  } = useTimelineRangeControls({
    setTimelineSettings,
    maxDayDifference,
    minDayDifference,
  });

  return (
    <RangeTimelineControlsStyled data-test-id='range-timeline-controls'>
      <Button onClick={handleOnClickToday} data-test-id='today-button'>
        <Icons.TargetIcon
          svgColor={Theme.colors.greyShades.grey2}
          svgSize={21}
        />
      </Button>
      {timelineSizeItems.length > 1 && (
        <TimelineSizeDropdown
          value={timelineSettings.timelineSize}
          handleChange={handleSetTimelineSize}
          timelineSizeItems={timelineSizeItems}
        />
      )}
      <LocalizedDatePicker
        showArrows
        type='timeline'
        value={timelineSettings.startDate.toDate()}
        onChange={(value) => handleDateChange(value, 'start')}
        clickHandlerLeftArrow={() => handleOnClickLeftArrow('start')}
        clickHandlerRightArrow={() => handleOnClickRightArrow('start')}
      />
      <LocalizedDatePicker
        showArrows
        type='timeline'
        value={timelineSettings.endDate.toDate()}
        onChange={(value) => handleDateChange(value, 'end')}
        clickHandlerLeftArrow={() => handleOnClickLeftArrow('end')}
        clickHandlerRightArrow={() => handleOnClickRightArrow('end')}
      />
    </RangeTimelineControlsStyled>
  );
};

export default RangeTimelineControls;
