import { FC } from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { AppBar, BadgeCounter } from 'team-hero-ui';

import type { IDesktopNavigationLinkProps } from './DesktopNavigationLink.types';
import { RedDotStyled } from '../common/NavigationLink.styled';

const DesktopNavigationLink: FC<IDesktopNavigationLinkProps> = ({
  children,
  to,
  disabled = false,
  showRedDot = false,
  badgeCount = 0,
  dataTestId,
  onClick,
  label,
  matchEntirePath = false,
}) => {
  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: matchEntirePath });
  return (
    <AppBar.Item
      disabled={disabled}
      onClick={onClick ? onClick : () => navigate(to)}
      active={!!match}
      dataTestId={dataTestId}
      label={label}
    >
      {children}
      {showRedDot && <RedDotStyled />}
      {badgeCount !== 0 && <BadgeCounter count={badgeCount} />}
    </AppBar.Item>
  );
};

export default DesktopNavigationLink;
