import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { ReformattedHtmlWrapper } from './ReformattedMissionHtml.styled';

interface IReformattedMissionHtmlProps {
  html: string;
}

const ReformattedMissionHtml: FC<IReformattedMissionHtmlProps> = ({ html }) => {
  return (
    <ReformattedHtmlWrapper>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={html || ''} />
    </ReformattedHtmlWrapper>
  );
};

export default ReformattedMissionHtml;
