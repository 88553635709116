import { TFunction } from 'i18next';

import type { IProjectShift } from 'interfaces/ProjectShift.interface';
import type { IColumnDefinitionType } from 'interfaces/Table/DataTableColumn.interface';
import { DateCellRenderer } from 'components/cellRenderers/DateCellRenderer/DateCellRenderer';
import ContactCellRenderer from 'components/cellRenderers/ContactCellRenderer';
import { BeginSeparatorCellRenderer } from 'components/cellRenderers/BeginSeparatorCellRenderer/BeginSeparatorCellRenderer';

export interface IShiftsColumnsFactoryProps {
  t: TFunction;
  enableClientContacts: boolean;
}

const customWidths = {
  XS: '55px',
  S: '100px',
  MS: '150px',
  M: '200px',
  L: '300px',
};

export const columnsOffset = 4;

export const shiftsColumnsFactory = ({
  t,
  enableClientContacts,
}: IShiftsColumnsFactoryProps): IColumnDefinitionType<IProjectShift>[] => {
  return [
    {
      key: 'status',
      label: '',
      visibility: true,
      minWidth: '14px',
      width: '14px',
      cellType: 'custom',
      skeletonType: 'status',
      cellRenderer: () => BeginSeparatorCellRenderer(),
      cellExportHandler: () => null,
    },
    {
      key: 'position',
      label: t('table.header.labels.shiftPosition'),
      visibility: true,
      cellType: 'string',
      minWidth: customWidths.M,
      isSortable: true,
    },
    {
      key: 'contact',
      label: t('table.header.labels.contact'),
      visibility: enableClientContacts,
      cellType: 'custom',
      minWidth: customWidths.L,
      skeletonType: 'contact',
      displayInExport: enableClientContacts,
      cellRenderer: ({ contact }) => (
        <ContactCellRenderer contactData={contact} />
      ),
    },
    {
      key: 'start',
      label: t('table.header.labels.startDate'),
      visibility: true,
      displayInMenu: true,
      sortType: 'date',
      cellType: 'custom',
      isSortable: true,
      cellRenderer: (shift) =>
        DateCellRenderer({ date: shift.start, showTime: false }),
    },
    {
      key: 'area',
      label: t('table.header.labels.area'),
      visibility: true,
      cellType: 'string',
      isSortable: true,
    },
    {
      key: 'task',
      label: t('table.header.labels.task'),
      visibility: true,
      cellType: 'string',
      minWidth: customWidths.S,
      isSortable: true,
    },
  ];
};
