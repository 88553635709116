import { styled } from 'styled-components';
import { device, theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

interface ILoginWrapperStyledProps {
  $startGradientColor?: string;
  $endGradientColor?: string;
}

export const LoginWrapperStyled = styled.div<ILoginWrapperStyledProps>`
  position: relative;
  overflow: hidden;
  height: 100dvh;
  width: 100%;
  ${flexFn('center', 'center')};
  background-image: ${({ $startGradientColor, $endGradientColor }) =>
    `linear-gradient(0deg, ${$endGradientColor || `#521dd5`} 3%, ${
      $startGradientColor || `#37efd3`
    } 80%)`};
  color: ${theme.colors.primary.black};

  @media ${device.sm} {
    background-image: ${({ $startGradientColor, $endGradientColor }) =>
      `linear-gradient(123deg, ${$startGradientColor || `#521dd5`} 22%, ${
        $endGradientColor || `#37efd3`
      } 72%)`};
  }
`;

export const LoginContentStyled = styled.div`
  display: grid;
  overflow: auto;
  grid-template-rows: ${pxToRem('125')} auto min-content;
  gap: ${pxToRem(20)};
  align-items: center;
  padding: ${pxToRem([40, 0, 0])};
  height: 100%;
  width: 100%;

  @media ${device.sm} {
    grid-template-rows: ${pxToRem('220')} auto min-content;
  }
`;

export const LoginContentWrapperStyled = styled.div`
  ${flexFn('center', 'center', 'column')};
  gap: ${pxToRem(20)};
  padding: ${pxToRem([0, 10])};
  z-index: 1;
  width: 100%;
`;

export const LoginCopyrightStyled = styled.div`
  ${flexFn('center', 'center', 'column')};
  width: 100%;
  height: 100%;
  background: ${theme.colors.greyShades.grey5};
  padding: ${pxToRem([10, 20])};

  @media ${device.sm} {
    border-radius: ${pxToRem([10, 10, 0, 0])};
    max-width: ${pxToRem(350)};
  }
`;

export const LoginCopyrightWrapperStyled = styled.div`
  ${flexFn('center', 'center')};
  flex: 1;
  height: 100%;
`;

export const LogoStyled = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const LogoWrapperStyled = styled.div`
  ${flexFn()};
  height: ${pxToRem(125)};

  @media ${device.sm} {
    height: ${pxToRem(250)};
  }
`;

export const LoginWrapperCardStyled = styled.div`
  ${flexFn('center', 'center', 'column')};
  background: ${theme.colors.primary.white};
  padding: ${pxToRem(20)};
  border-radius: ${pxToRem(10)};
  color: ${theme.colors.primary.black};
  width: 100%;
  box-shadow: 0 0 ${pxToRem(12)} 0 ${theme.colors.greyShades.grey1}20;
  max-width: ${pxToRem(500)};
`;
