import { FC, ReactElement } from 'react';

import type { TFeatureFlags } from 'components/FeatureFlag/featureFlag.type';
import { useFlags } from './useFlags.hook';

interface IFeatureFlagProps {
  flag: TFeatureFlags;
  children: ReactElement;
}

/**
 * FeatureFlag component
 * @description A component that renders its children only if the feature flag is enabled
 * @param flag the feature flag to check
 * @param children the children to render if the flag is enabled
 */
const FeatureFlag: FC<IFeatureFlagProps> = ({ flag, children }) => {
  const { isFeatureFlagEnabled } = useFlags();

  return isFeatureFlagEnabled(flag) ? children : null;
};

export default FeatureFlag;
