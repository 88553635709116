import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const RowActionStyled = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.greyShades.grey2};
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  font-size: ${pxToRem(15)};
  border-radius: ${pxToRem(5)};
`;

export const RowActionContentStyled = styled.div`
  height: 100%;
  width: inherit;
  ${flexFn('flex-start')};
  gap: ${pxToRem(5)};
  position: relative;
`;

export const IconWithSeparatorWrapperStyled = styled.div`
  ${flexFn('flex-start')}
  height: 100%;
  min-width: ${pxToRem(48)};
  gap: ${pxToRem(0)};
`;

export const Separator = styled.div`
  width: 0;
  height: 100%;
  box-shadow: ${pxToRem([0, 0, 10, 1])} rgb(0 0 0 / 80%);
  clip-path: inset(0px -15px 0px 0px);
`;

export const SeparatorDotted = styled.div`
  height: 100%;
  margin: ${pxToRem([0, 10])};
  border-left: ${({ theme }) =>
    `${pxToRem(2)} dotted ${theme.colors.greyShades.grey4}`};
`;

export const RowLoadingWrapperStyled = styled.div`
  ${flexFn('flex-start')};
`;
