import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const TimelineBodyOuterStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')}
  width: 100%;
`;

interface ITimelineBodyGroupWrapperStyledProps {
  $minHeight: number;
}

export const TimelineBodyGroupWrapperStyled = styled.div<ITimelineBodyGroupWrapperStyledProps>`
  flex: 0;
  ${flexFn('center', 'stretch')}
  min-height: ${({ $minHeight }) =>
    $minHeight ? pxToRem($minHeight) : pxToRem(0)};
`;

interface ITimelineBodyItemWrapperProps {
  $width: number;
}

export const TimelineBodyItemWrapper = styled.div<ITimelineBodyItemWrapperProps>`
  position: relative;
  width: ${({ $width }) => ($width ? pxToRem($width) : pxToRem(0))};
  z-index: 1;
`;
