import { FC } from 'react';
import { Icons, Theme, Utils, device } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import type { INoResultGraphicProps } from './NoResultGraphic.types';
import {
  IconsWrapperStyled,
  NoResultGraphicWrapper,
  TitleStyled,
  TypographyStyled,
} from './NoResultGraphic.styled';

const NoResultGraphic: FC<INoResultGraphicProps> = ({
  svgColor = Theme.colors.greyShades.grey2,
  title,
  bottomText,
  hasSetFilters = false,
  showGraphic = true,
}) => {
  const { t } = useTranslation('general');

  const { useMediaQuery } = Utils;
  const isXlScreenSize = useMediaQuery(device.xl);
  const isSmScreenSize = useMediaQuery(device.sm);

  const svgSize = isSmScreenSize ? (isXlScreenSize ? 600 : 300) : 200;

  return (
    <NoResultGraphicWrapper>
      <IconsWrapperStyled>
        {hasSetFilters ? (
          <>
            {showGraphic && (
              <Icons.GraphicServiceEmpty
                svgSize={svgSize}
                svgColor={svgColor}
              />
            )}
            {title && <TitleStyled>{title}</TitleStyled>}
            <TypographyStyled>
              {bottomText ? bottomText : t('graphic.label.noFilterResults')}
            </TypographyStyled>
          </>
        ) : (
          <>
            {showGraphic && (
              <Icons.GraphicMeditation svgColor={svgColor} svgSize={svgSize} />
            )}
            {title && <TitleStyled>{title}</TitleStyled>}
            <TypographyStyled>
              {bottomText ? bottomText : t('graphic.label.noResults')}
            </TypographyStyled>
          </>
        )}
      </IconsWrapperStyled>
    </NoResultGraphicWrapper>
  );
};

export default NoResultGraphic;
