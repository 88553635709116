import { FC } from 'react';

import type { ITimelineBackdropProps } from './TimelineBackdrop.types';
import {
  CurrentDateLineStyled,
  TimelineBackdropWrapperStyled,
} from './TimelineBackdrop.styled';
import {
  calculateBackdropItems,
  calculateCurrentDateLineOffset,
} from './TimelineBackdrop.helper';
import TimelineBackdropItem from '../TimelineBackdropItem';

const TimelineBackdrop: FC<ITimelineBackdropProps> = ({
  resolution,
  start,
  end,
  backdropWidth,
  showCurrentDateLine = false,
  groupId,
  backdropItemClickCallback,
}) => {
  const backdropItems = calculateBackdropItems(
    resolution,
    start,
    end,
    backdropWidth
  );

  const currentDateLineOffset = calculateCurrentDateLineOffset(
    start,
    end,
    backdropWidth
  );

  return (
    <TimelineBackdropWrapperStyled>
      {backdropItems.map((item) => (
        <TimelineBackdropItem
          key={item.key}
          isStriped={item.striped}
          size={item.size}
          groupId={groupId}
          startTime={item.startTime}
          backdropItemClickCallback={backdropItemClickCallback}
        />
      ))}
      {showCurrentDateLine &&
        currentDateLineOffset > 0 &&
        currentDateLineOffset < backdropWidth && (
          <CurrentDateLineStyled $left={currentDateLineOffset} />
        )}
    </TimelineBackdropWrapperStyled>
  );
};

export default TimelineBackdrop;
