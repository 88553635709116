import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icons, Theme } from 'team-hero-ui';

import type { IDashboardActionCardProps } from './DashboardActionCard.types';
import { CardIndicatorStyled } from 'components/Dashboard/Dashboard.styled';
import {
  ActionCardStyled,
  ContentStyled,
  EmptyProjectCardStyled,
} from './DashboardActionCard.styled';

const DashboardActionCard: FC<IDashboardActionCardProps> = ({
  title,
  content,
  buttonText,
  type,
  icon,
  onActionButtonClick,
}) => {
  const { t } = useTranslation('general');

  return (
    <ActionCardStyled>
      <CardIndicatorStyled
        $bgColor={Theme.colors.greyShades.grey2}
        $color={Theme.colors.primary.white}
      >
        {type ? t(`dashboard.actionCard.title.${type}`) : title}
      </CardIndicatorStyled>
      <EmptyProjectCardStyled>
        <ContentStyled>
          {type ? (
            <>
              <p>{t(`dashboard.actionCard.message1.${type}`)}</p>
              <p>{t(`dashboard.actionCard.message2.${type}`)}</p>
            </>
          ) : (
            <p>{content}</p>
          )}
          <Button
            color='borderGrey'
            onClick={onActionButtonClick}
            startIcon={
              <Icons.PlusIconV1 svgColor='currentColor' svgSize={14} />
            }
          >
            {type ? t(`dashboard.actionCard.addButton.${type}`) : buttonText}
          </Button>
        </ContentStyled>
        {icon}
      </EmptyProjectCardStyled>
    </ActionCardStyled>
  );
};

export default DashboardActionCard;
