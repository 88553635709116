import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

export const NameStyled = styled.span`
  margin-left: ${pxToRem(8)};
  font-size: ${pxToRem(11)};
  max-width: ${pxToRem(120)};
  line-height: ${pxToRem(16)};
  color: inherit;
`;
