import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const WorkplannerTimelineViewStyled = styled.div`
  ${flexFn('flex-start', 'start', 'column')}
  height: 100%;
  margin: ${pxToRem([30, 20, 0, 20])};
  min-width: ${pxToRem(1000)};
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.greyShades.grey6};
`;
