import { styled } from 'styled-components';
import { device, Theme, Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

export const CardIndicatorStyled = styled.div<{
  $color: string;
  $bgColor: string;
}>`
  z-index: 1;
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};
  font-size: ${pxToRem(12)};
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  border-top-left-radius: ${pxToRem(4)};
  border-top-right-radius: ${pxToRem(4)};
  width: fit-content;
  padding: ${pxToRem([6, 12])};
`;

export const DashboardTitle = styled.p`
  color: ${Theme.colors.primary.grey};
  font-size: ${pxToRem(18)};
  margin-bottom: ${pxToRem(12)};
  text-transform: uppercase;
  font-weight: 600;

  @media ${device.sm} {
    margin-bottom: ${pxToRem(32)};
  }
`;
