import { FC, MouseEvent } from 'react';
import { ToggleButtonGroup, Icons, Theme } from 'team-hero-ui';

import type { TWorkplannerViewMode } from 'components/Workplanner/interfaces/viewmode.interface';

interface IToggleViewModeProps {
  value: TWorkplannerViewMode;
  setValue: (x: TWorkplannerViewMode) => void;
}

const ToggleViewMode: FC<IToggleViewModeProps> = ({ value, setValue }) => {
  const handleChange = (
    _event: MouseEvent<HTMLElement>,
    viewMode: TWorkplannerViewMode
  ) => {
    // state can not be null
    if (viewMode !== null) {
      setValue(viewMode);
    }
  };

  return (
    <ToggleButtonGroup.ToggleButtonGroup
      value={value}
      onChange={handleChange}
      exclusive
      size='small'
      dataTestId='toggle-view-mode'
    >
      <ToggleButtonGroup.ToggleButton
        value='timeline'
        dataTestId={'toggle-view-mode-timeline'}
      >
        <Icons.TimelineIcon svgColor={Theme.colors.primary.grey} svgSize={21} />
      </ToggleButtonGroup.ToggleButton>
      <ToggleButtonGroup.ToggleButton
        value='list'
        dataTestId={'toggle-view-mode-list'}
      >
        <Icons.ListIcon svgColor={Theme.colors.primary.grey} svgSize={21} />
      </ToggleButtonGroup.ToggleButton>
    </ToggleButtonGroup.ToggleButtonGroup>
  );
};

export default ToggleViewMode;
