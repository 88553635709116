import { styled } from 'styled-components';
import { Utils, theme } from 'team-hero-ui';

const { pxToRem } = Utils;

export const RedDotStyled = styled.div`
  width: ${pxToRem(8)};
  height: ${pxToRem(8)};
  border-radius: ${pxToRem(10)};
  position: absolute;
  background: ${theme.colors.primary.red};
  margin-top: ${pxToRem(-12)};
  margin-right: ${pxToRem(-12)};
  top: 50%;
  right: 50%;
`;

export const BadgeWrapperStyled = styled.div`
  position: absolute;
  margin-bottom: ${pxToRem(40)};
  margin-left: ${pxToRem(40)};
`;
