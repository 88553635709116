import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { TRootState } from 'store';
import type { IMessagesState } from './messages.slice.type';

export const initialState: IMessagesState = {
  isContactDetailsModalOpen: false,
  isCreateNewConversation: false,
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessagesDetails: (
      state: IMessagesState,
      {
        payload: {
          isContactDetailsModalOpen,
          isCreateNewConversation,
          mission,
        },
      }: PayloadAction<Partial<IMessagesState>>
    ) => {
      state.isContactDetailsModalOpen = isContactDetailsModalOpen ?? false;
      state.isCreateNewConversation = isCreateNewConversation ?? false;
      state.mission = mission;
    },
    closeDetailsModal: (state: IMessagesState) => {
      state.isContactDetailsModalOpen = false;
      state.isCreateNewConversation = false;
    },
    closeAndResetDetailsModal: (state: IMessagesState) => {
      state.isContactDetailsModalOpen = false;
      state.isCreateNewConversation = false;
      state.mission = undefined;
    },
  },
});

export const {
  setMessagesDetails,
  closeDetailsModal,
  closeAndResetDetailsModal,
} = messagesSlice.actions;

export const messagesSelector = (state: TRootState): IMessagesState =>
  state.messages;

export default messagesSlice.reducer;
