import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem } = Utils;

export const ReformattedHtmlWrapper = styled.div`
  font-size: ${pxToRem(16)};
  width: 100%;

  pre {
    white-space: normal;
  }

  ul,
  ol {
    padding-left: ${pxToRem(20)};
  }

  > ul,
  > ol {
    margin-bottom: ${pxToRem(20)};
  }
`;
