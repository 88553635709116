import { FC } from 'react';

import type { IMissionJobsTabProps } from './MissionJobsTab.types';
import { MissionJobsStyled } from './MissionJobsTab.styled';
import Workplanner from 'components/Workplanner';

const MissionJobsTab: FC<IMissionJobsTabProps> = ({ mission }) => {
  return (
    <MissionJobsStyled>
      <Workplanner mission={mission} />
    </MissionJobsStyled>
  );
};

export default MissionJobsTab;
