import { styled } from 'styled-components';
import { device, Theme, Utils } from 'team-hero-ui';

import {
  StyledTableCell,
  StyledTableBodyInnerRow,
  TruncatedCellStyled,
} from 'components/Table/components/TableBody/TableBody.styled';
import { CommonCellContentStyled } from 'components/cellRenderers/Common.styled';

const { pxToRem } = Utils;

export const StyledResultRow = styled.div`
  font-size: ${pxToRem(13)};
  border-radius: ${pxToRem(5)};
  cursor: pointer;
  transition: all 0.05s ease-in;
  min-width: fit-content;
  width: auto;
  background-color: ${Theme.colors.primary.white};
  margin-bottom: ${pxToRem(4)};
  padding: ${pxToRem([0, 8])};

  @media ${device.sm} {
    padding: 0;
  }

  ${TruncatedCellStyled}, ${CommonCellContentStyled} {
    font-size: ${pxToRem(12)};
    @media ${device.sm} {
      font-size: ${pxToRem(14)};
    }
  }
`;

export const StyledResultInnerRow = styled(StyledTableBodyInnerRow)``;

export const StyledRowCell = styled(StyledTableCell)`
  background-color: ${({ $isFirst }) =>
    $isFirst ? Theme.colors.greyShades.grey6 : 'inherit'};
`;
