import { styled } from 'styled-components';
import { Box, Button, Theme, Utils, device } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

interface IContainerStyledProps {
  $displayFlexColumn: boolean;
}

export const ContainerStyled = styled.div<IContainerStyledProps>`
  ${flexFn('flex-end', 'center', 'column-reverse')};
  height: 100%;
  background-color: ${Theme.colors.greyShades.grey7};
  gap: ${pxToRem(12)};
  @media ${device.sm} {
    ${({ $displayFlexColumn }) =>
      $displayFlexColumn
        ? flexFn('center', 'center', 'column-reverse')
        : flexFn('flex-end', 'center', 'row')};
  }
`;

export const ItemStyled = styled.div`
  ${flexFn('center', 'start', 'column')};
  width: 100%;
`;

export const LogoItemStyled = styled(ItemStyled)`
  position: absolute;
  bottom: 0;
  right: 0;
  ${flexFn('flex-end', 'end', 'column')};

  @media ${device.sm} {
    bottom: initial;
    right: initial;
    position: relative;
  }
`;

export const LogoWrapperStyled = styled.div`
  ${flexFn('center', 'center', 'column')};
  width: 67%;
  @media ${device.sm} {
    width: 100%;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export const ContentWrapperStyled = styled(Box)`
  ${flexFn('center', 'start', 'column')};
  gap: ${pxToRem(8)};
  max-width: ${pxToRem(300)};
  padding: ${pxToRem(20)};
  height: 100%;
`;

export const ErrorH1 = styled.h1`
  color: ${Theme.colors.primary.red};
  font-size: ${pxToRem(50)};
  font-weight: 600;
  text-transform: uppercase;
`;

export const ErrorH2 = styled.h2`
  color: ${Theme.colors.primary.grey};
  font-size: ${pxToRem(15)};
  font-weight: 600;
  text-transform: uppercase;
`;

export const DescriptionStyled = styled.p`
  padding: ${pxToRem([24, 0])};
  color: ${Theme.colors.primary.grey};
  font-size: ${pxToRem(12)};
  line-height: 1.67;
`;

export const ReportButtonStyled = styled(Button)`
  &.MuiButtonBase-root {
    &.MuiButton-text {
      font-size: ${pxToRem(12)};
    }
  }
`;
