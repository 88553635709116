import { Utils } from 'team-hero-ui';
import { styled } from 'styled-components';

const { flexFn, pxToRem } = Utils;

export const ContactCellContainerStyled = styled.div`
  ${flexFn('flex-start')}
  gap: ${pxToRem(10)};
`;

export const SkeletonWrapper = styled.div`
  width: 100%;
`;
