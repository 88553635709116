import { Fragment } from 'react';

import type { TTimelineItemVariant } from 'components/Timeline/interfaces/timeline.interface';
import type { ITimelineBodyProps } from './TimelineBody.type';
import {
  filterDisplayItemsByTimeFrame,
  getDisplayItems,
  getMaxOverlappingItems,
} from 'components/Timeline/helper/timelineRenderer.helper';
import {
  TimelineBodyGroupWrapperStyled,
  TimelineBodyItemWrapper,
  TimelineBodyOuterStyled,
} from './TimelineBody.styled';
import { RowItemsRenderer } from '../RowItemsRenderer/RowItemsRenderer';
import TimelineBackdrop from './components/TimelineBackdrop';

const TimelineBody = <T extends TTimelineItemVariant>({
  width,
  start,
  end,
  leftLegendWidth = 0,
  rightLegendWidth = 0,
  rowHeight = 92,
  groups = [],
  items = [],
  resolution,
  groupKey,
  ItemRenderer,
  LeftLegendRenderer,
  RightLegendRenderer,
  snapResolution,
  showCurrentDateLine = false,
  missionId,
  backdropItemClickCallback,
  collapsedContent,
}: ITimelineBodyProps<T>): JSX.Element => {
  return (
    <TimelineBodyOuterStyled data-component='timeline-body'>
      {groups.map((group) => {
        const currentRowItems = [...items].filter(
          (item) => item.group === group.id
        );

        const displayItems = getDisplayItems(
          currentRowItems,
          snapResolution ? snapResolution : resolution,
          start,
          end
        );

        // filter for visible items
        const filteredDisplayItems = filterDisplayItemsByTimeFrame(
          displayItems,
          start,
          end
        );

        const rowCount = getMaxOverlappingItems(filteredDisplayItems);

        return (
          <Fragment key={`timelinebody-group-${group.id}`}>
            <TimelineBodyGroupWrapperStyled $minHeight={rowHeight * rowCount}>
              <LeftLegendRenderer
                items={filteredDisplayItems}
                width={leftLegendWidth}
                groupLabel={group.id}
                groupKey={groupKey}
                missionId={missionId}
              />
              <TimelineBodyItemWrapper
                $width={width - leftLegendWidth - rightLegendWidth}
              >
                <TimelineBackdrop
                  start={start}
                  end={end}
                  resolution={resolution}
                  backdropWidth={width - leftLegendWidth - rightLegendWidth}
                  showCurrentDateLine={showCurrentDateLine}
                  groupId={group.id}
                  backdropItemClickCallback={backdropItemClickCallback}
                />
                <RowItemsRenderer
                  displayItems={filteredDisplayItems}
                  start={start}
                  end={end}
                  totalWidth={width - leftLegendWidth - rightLegendWidth}
                  itemHeight={rowHeight}
                  ItemRenderer={ItemRenderer}
                />
              </TimelineBodyItemWrapper>
              {RightLegendRenderer && (
                <RightLegendRenderer
                  items={filteredDisplayItems}
                  width={rightLegendWidth}
                  groupKey={groupKey}
                  groupId={group.id}
                />
              )}
            </TimelineBodyGroupWrapperStyled>
            {collapsedContent && collapsedContent(+group.id)}
          </Fragment>
        );
      })}
    </TimelineBodyOuterStyled>
  );
};

export default TimelineBody;
