import { styled, css } from 'styled-components';
import { Utils, device } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

interface ILayoutOuterStyledProps {
  $mobileNavigationHeight: number;
  $isTablet: boolean;
}

const getLayoutOuterStyles = (
  isTablet = false,
  mobileNavigationHeight = 64
) => {
  return isTablet
    ? css`
        display: grid;
        grid-template-columns: ${pxToRem(50)} auto;
        min-height: 100dvh;
      `
    : css`
        padding-bottom: ${pxToRem(mobileNavigationHeight)};
        display: block;
        position: relative;
        width: 100%;
        height: 100dvh;
      `;
};

export const LayoutOuterStyled = styled.div<ILayoutOuterStyledProps>`
  ${({ $mobileNavigationHeight, $isTablet }) =>
    getLayoutOuterStyles($isTablet, $mobileNavigationHeight)}
`;

export const LayoutNavigationStyled = styled.div``;

export const LayoutInnerStyled = styled.div`
  overflow: auto;
  height: 100%;
  position: relative;
  ${flexFn('flex-start', 'stretch', 'column')};
  margin-bottom: ${pxToRem(-64)};

  @media ${device.lg} {
    margin-bottom: 0;
  }
`;
