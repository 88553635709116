import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

import { shadowLight } from 'helpers/styles/styles.helper';

const { flexFn, pxToRem } = Utils;

export const EmptyProjectCardStyled = styled.div`
  ${flexFn('space-between', 'end')};
  background-color: ${Theme.colors.primary.white};
  padding: ${pxToRem(12)};
  gap: ${pxToRem(12)};
  border-radius: ${pxToRem(4)};
  border-top-left-radius: 0;
  color: ${Theme.colors.greyShades.grey4};
  ${shadowLight};

  p {
    font-size: ${pxToRem(14)};
    color: ${Theme.colors.primary.grey};
    line-height: 1.4;
  }
`;

export const ContentStyled = styled.div`
  ${flexFn('flex-start', 'start', 'column')};
  gap: ${pxToRem(12)};
`;

export const ActionCardStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  border-radius: ${pxToRem(4)};
  margin-top: ${pxToRem(40)};
`;
