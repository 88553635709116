import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

interface IStyledTableHeaderCellProps {
  $minWidth: string;
  $width: string | undefined;
  $isSorted: boolean;
}

interface IButtonWrapperProps {
  $keepVisible: boolean;
}

interface IStyledTableHeaderRowProps {
  $gridColsWidth: string;
}

export const ButtonWrapper = styled.div<IButtonWrapperProps>`
  display: ${({ $keepVisible }) => ($keepVisible ? 'inline-block' : 'none')};
`;

export const StyledTableHeaderCell = styled.div<IStyledTableHeaderCellProps>`
  font-size: ${pxToRem(12)};
  font-weight: ${({ $isSorted }) => ($isSorted ? 'bold' : 600)};
  color: ${({ theme }) => theme.colors.greyShades.grey3};
  text-align: left;
  text-transform: uppercase;
  height: ${pxToRem(26)};
  min-width: ${({ $minWidth }) => pxToRem($minWidth)};
  width: ${({ $width }) => ($width ? pxToRem($width) : null)};
  padding-right: ${pxToRem(5)};
  ${flexFn('flex-start')}
  gap: ${pxToRem(5)};

  &:hover {
    ${ButtonWrapper} {
      display: inline-block;
    }
  }
`;

export const StyledTableHeaderSortWrapper = styled.div`
  ${flexFn('flex-end')}
`;

export const StyledTableHeaderRow = styled.div<IStyledTableHeaderRowProps>`
  display: grid;
  grid-template-columns: ${({ $gridColsWidth }) => $gridColsWidth};
  font-size: ${pxToRem(15)};
`;
