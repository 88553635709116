import { FC } from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { BadgeCounter, BottomNavigation } from 'team-hero-ui';

import type { IMobileNavigationLinkProps } from './MobileNavigationLink.types';
import {
  BadgeWrapperStyled,
  RedDotStyled,
} from '../common/NavigationLink.styled';

const MobileNavigationLink: FC<IMobileNavigationLinkProps> = ({
  to,
  children,
  height,
  disabled = false,
  showRedDot = false,
  dataTestId,
  badgeCount = 0,
  label,
  matchEntirePath = false,
}) => {
  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: matchEntirePath });
  return (
    <BottomNavigation.NavigationAction
      height={height}
      active={!!match}
      disabled={disabled}
      onClick={() => navigate(to)}
      dataTestId={dataTestId}
      label={label}
    >
      {children}
      {showRedDot && <RedDotStyled />}
      {badgeCount !== 0 && (
        <BadgeWrapperStyled>
          <BadgeCounter count={badgeCount} />
        </BadgeWrapperStyled>
      )}
    </BottomNavigation.NavigationAction>
  );
};

export default MobileNavigationLink;
