import { styled } from 'styled-components';
import { ChipLabel } from 'team-hero-ui';

interface IChipLabelStyledProps {
  $calendarColorBg: string | null;
  $calendarColorText: string | null;
}

export const ChipLabelStyled = styled(ChipLabel.General)<IChipLabelStyledProps>`
  background-color: ${({ $calendarColorBg }) => $calendarColorBg};
  color: ${({ $calendarColorText }) => $calendarColorText};
`;
