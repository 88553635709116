import { FC } from 'react';
import { IconButton, Icons } from 'team-hero-ui';

import type { IId } from 'interfaces/IId.interface';
import { preventEventWrapper } from 'helpers/events/preventEventWrapper.helper';
import { FlexCellRendererStyled } from 'components/cellRenderers/Common.styled';
import { MoreIconWrapperStyled } from './MoreCellRenderer.styled';

interface IMoreCellRendererProps<T> {
  row: T;
  setRowIdMenuOpen: (id: number) => void;
}

export const MoreCellRenderer: FC<IMoreCellRendererProps<IId>> = ({
  row: { id },
  setRowIdMenuOpen,
}) => {
  const setId = () => {
    setRowIdMenuOpen(id);
  };
  return (
    <FlexCellRendererStyled className='more-icon-row'>
      <MoreIconWrapperStyled>
        <IconButton
          onClick={(e) => preventEventWrapper(e, setId)}
          color='primary'
          styleType='tableMore'
          edge='start'
        >
          <Icons.MoreIcon svgSize={20} svgColor='currentColor' />
        </IconButton>
      </MoreIconWrapperStyled>
    </FlexCellRendererStyled>
  );
};
