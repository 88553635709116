import { FC } from 'react';
import { AvatarStatus } from 'team-hero-ui';

import type { IContactSummaryProps } from './ContactSummary.type';
import useGetImageUrl from 'hooks/useGetImageUrl.hook';
import { useElementSize } from 'hooks/useElementSize';
import {
  ContactSummaryMoreIndicatorStyled,
  ContactSummaryWrapperStyled,
} from './ContactSummary.styled';
import { useGetContactItemQuery } from 'services/teamHeroApi.service';
import { calculateContactItemsMaxItems } from './ContactSummary.helper';
import { getContactIdFromIriOrContact } from 'helpers/contact/getContactId.helper';

interface ILeftLegendContact {
  contactIri: string;
}

const LeftLegendContact: FC<ILeftLegendContact> = ({ contactIri }) => {
  const { data: contactData } = useGetContactItemQuery({
    id: getContactIdFromIriOrContact(contactIri),
  });

  const { imageUrls } = useGetImageUrl({
    images: contactData?.avatar,
    thumbnailSize: 'small',
  });
  const imgUrl = Array.isArray(imageUrls) ? imageUrls[0] : imageUrls;

  return (
    <AvatarStatus
      size={30}
      image={imgUrl}
      firstName={contactData?.firstName}
      lastName={contactData?.lastName}
    />
  );
};

const ContactSummary: FC<IContactSummaryProps> = ({
  contacts,
  keyIdentifier,
}) => {
  const [avatarWrapperRef, { width: wrapperWidth, height: wrapperHeight }] =
    useElementSize();

  const contactsLimit = calculateContactItemsMaxItems(
    40,
    35,
    wrapperHeight,
    wrapperWidth
  );

  return (
    <ContactSummaryWrapperStyled ref={avatarWrapperRef}>
      {contacts.slice(0, contactsLimit).map((contact) => (
        <LeftLegendContact
          key={`${keyIdentifier}-${contact.contact}`}
          contactIri={contact.contact}
        />
      ))}
      {contacts.slice(contactsLimit).length > 0 && (
        <ContactSummaryMoreIndicatorStyled>
          +{contacts.slice(contactsLimit).length}
        </ContactSummaryMoreIndicatorStyled>
      )}
    </ContactSummaryWrapperStyled>
  );
};

export default ContactSummary;
