import type {
  IProjectShift,
  IProjectShiftWorkplanner,
} from 'interfaces/ProjectShift.interface';

export const sumWorkplannerShiftsByMultipleCriteria: (
  items: IProjectShiftWorkplanner[],
  criteria: Array<keyof IProjectShiftWorkplanner>
) => {
  [key: string]: IProjectShiftWorkplanner[];
} = (items, criteria) => {
  return items.reduce(
    (
      result: {
        [key: string]: IProjectShiftWorkplanner[];
      },
      currentValue: IProjectShiftWorkplanner
    ) => {
      const concatCriteria = criteria.reduce(
        (criteriaResult, currentCriteria) => {
          return `${criteriaResult}${currentValue[currentCriteria]}`;
        },
        ''
      );

      (result[concatCriteria] = result[concatCriteria] || []).push(
        currentValue
      );
      return result;
    },
    {}
  );
};

export const sumShiftsByMultipleCriteria: (
  items: IProjectShift[],
  criteria: Array<keyof IProjectShift>
) => {
  [key: string]: IProjectShift[];
} = (items, criteria) => {
  return items.reduce(
    (
      result: {
        [key: string]: IProjectShift[];
      },
      currentValue: IProjectShift
    ) => {
      const concatCriteria = criteria.reduce(
        (criteriaResult, currentCriteria) => {
          return `${criteriaResult}${currentValue[currentCriteria]}`;
        },
        ''
      );

      (result[concatCriteria] = result[concatCriteria] || []).push(
        currentValue
      );
      return result;
    },
    {}
  );
};
