import { ReactElement } from 'react';

import type { ITimelineProps } from './Timeline.type';
import type { TTimelineItemVariant } from './interfaces/timeline.interface';
import TimelineBody from './components/TimelineBody';

const Timeline = <T extends TTimelineItemVariant>({
  timelineWidth,
  timelineStart,
  timelineEnd,
  ...props
}: ITimelineProps<T>): ReactElement => {
  return (
    <TimelineBody
      width={timelineWidth}
      start={timelineStart}
      end={timelineEnd}
      {...props}
    />
  );
};

export default Timeline;
