import { styled } from 'styled-components';
import { device, Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const DashboardStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  height: calc(100dvh - ${pxToRem(64)});
  background-color: ${Theme.colors.greyShades.grey5};

  @media ${device.sm} {
    height: 100%;
  }
`;

export const DashboardWrapperStyled = styled.div`
  padding: ${pxToRem(12)};
  overflow: auto;
  height: 100%;

  @media ${device.md} {
    padding: ${pxToRem(24)};
  }
`;

export const DashboardHeaderStyled = styled.div`
  height: ${pxToRem(60)};
  background-color: ${Theme.colors.primary.white};
  padding: ${pxToRem([6, 12])};
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(12)};
`;

export const HeaderTitleWrapperStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')}
`;

export const HeaderTitleStyled = styled.p`
  color: ${Theme.colors.primary.grey};
  font-size: ${pxToRem(20)};
  font-weight: 600;
  word-break: break-word;
`;
