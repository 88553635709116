import type { TFeatureFlags } from 'components/FeatureFlag/featureFlag.type';

interface IUseFlagsReturn {
  isFeatureFlagEnabled: (flag: TFeatureFlags) => boolean;
}

/**
 * useFlags hook
 * @description A hook that returns the feature flags
 */
export const useFlags = (): IUseFlagsReturn => {
  const flags: Record<TFeatureFlags, boolean> = {
    clientPool: false,
    clientRatings: false,
    clientDashboardRatingsCard: false,
    clientDashboardApplicationsCard: false,
  };

  const isFeatureFlagEnabled = (flag: TFeatureFlags): boolean => {
    return flags[flag];
  };

  return { isFeatureFlagEnabled };
};
