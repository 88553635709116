import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { IShiftRightLegendContentProps } from './ShiftRightLegendContent.type';
import {
  InnerColumnStyled,
  InnerStyled,
  LegendWrapperStyled,
  TextStyled,
} from './ShiftRightLegendContent.styled';
import FormattedNumber from 'components/FormattedNumber';

const ShiftRightLegendContent: FC<IShiftRightLegendContentProps> = ({
  width,
  hoursTotal,
}) => {
  const { t } = useTranslation('general');
  return (
    <LegendWrapperStyled $width={width}>
      <InnerStyled>
        <InnerColumnStyled>
          <TextStyled>
            <FormattedNumber value={hoursTotal} maxDigits={1} />{' '}
            {t('hourShort')}
          </TextStyled>
        </InnerColumnStyled>
      </InnerStyled>
    </LegendWrapperStyled>
  );
};

export default ShiftRightLegendContent;
