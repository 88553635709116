import { FC } from 'react';
import {
  ClickAwayListener,
  Icons,
  Checkbox,
  IconButton,
  Loader,
} from 'team-hero-ui';

import type { IRowActionProps } from './RowAction.type';
import { preventEventWrapper } from 'helpers/events/preventEventWrapper.helper';
import {
  IconWithSeparatorWrapperStyled,
  RowActionContentStyled,
  RowActionStyled,
  RowLoadingWrapperStyled,
  Separator,
  SeparatorDotted,
} from './RowAction.styled';

export const RowAction: FC<IRowActionProps> = ({
  id,
  isSelected,
  hideSeparator = false,
  additionalActions,
  isLoading = false,
  onCloseMore,
  onEditClick,
  onDuplicateClick,
  onDeleteClick,
  onSelect,
}) => {
  const iconColor = 'currentColor';
  const iconSize = 24;

  const DefaultButtons = () => (
    <>
      {onEditClick && id && (
        <IconButton
          styleType='tableOverlay'
          onClick={(e) =>
            preventEventWrapper(e, () => {
              onEditClick(id);
              onCloseMore();
            })
          }
        >
          <Icons.EditIcon svgSize={iconSize} svgColor={iconColor} />
        </IconButton>
      )}
      {onDuplicateClick && id && (
        <IconButton
          styleType='tableOverlay'
          onClick={(e) =>
            preventEventWrapper(e, () => {
              onDuplicateClick(id);
              onCloseMore();
            })
          }
        >
          <Icons.DuplicateIcon svgSize={iconSize} svgColor={iconColor} />
        </IconButton>
      )}
      {onDeleteClick && id && (
        <IconButton
          styleType='tableOverlay'
          onClick={(e) =>
            preventEventWrapper(e, () => {
              onDeleteClick(id);
              onCloseMore();
            })
          }
        >
          <Icons.DeleteIcon svgSize={iconSize} svgColor={iconColor} />
        </IconButton>
      )}
      {!hideSeparator && <SeparatorDotted />}
    </>
  );

  return (
    <ClickAwayListener onClickAway={onCloseMore}>
      <RowActionStyled data-component='table-row-actions'>
        <RowActionContentStyled>
          <IconWithSeparatorWrapperStyled>
            <IconButton
              type='button'
              styleType='tableOverlay'
              onClick={(e) => preventEventWrapper(e, onCloseMore)}
            >
              <Icons.MoreIcon svgSize={20} svgColor='currentColor' />
            </IconButton>
            <Separator />
          </IconWithSeparatorWrapperStyled>
          {onSelect && (
            <Checkbox
              onChange={onSelect}
              styleType='darkTableHover'
              onClick={preventEventWrapper}
              checked={isSelected}
            />
          )}
          {isLoading ? (
            <RowLoadingWrapperStyled>
              <Loader loaderSize='small' loaderType='static' />
            </RowLoadingWrapperStyled>
          ) : (
            <>
              <DefaultButtons />
              {additionalActions}
            </>
          )}
        </RowActionContentStyled>
      </RowActionStyled>
    </ClickAwayListener>
  );
};
