import { FC } from 'react';

import Avatar from 'components/Avatar';
import { ApplicationCardActiveStyled } from './ApplicationCardActive.styled';
import type { IApplicationCardActiveProps } from './ApplicationCardActive.types';
import { getApplicationIdsWithSameContact } from 'components/ClientLoginMissionDetails/Tabs/MissionApplicationsTab/helpers/applications.helper';
import RatingButtons from 'components/ClientLoginMissionDetails/Tabs/MissionApplicationsTab/components/RatingButtons';

const ApplicationCardActive: FC<IApplicationCardActiveProps> = ({
  missionId,
  allApplications,
  application,
  onAvatarClick,
}) => {
  return (
    <ApplicationCardActiveStyled>
      <Avatar
        sourceType='file'
        size={'L'}
        images={application.contact.avatar || ''}
        firstName={application.contact.firstName}
        lastName={application.contact.lastName}
        thumbnailSize='small'
        onClick={() => onAvatarClick(application)}
      />
      <RatingButtons
        missionId={missionId}
        applicationIds={getApplicationIdsWithSameContact(
          allApplications,
          application.contact.id
        )}
      />
    </ApplicationCardActiveStyled>
  );
};

export default ApplicationCardActive;
