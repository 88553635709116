import { FC, Fragment } from 'react';
import moment from 'moment';

import type {
  ITimelineDisplayItem,
  ITimelineItemProps,
  TTimelineItemVariant,
} from 'components/Timeline/interfaces/timeline.interface';

interface IRowItemsRendererProps<T> {
  displayItems: ITimelineDisplayItem<T>[];
  start: moment.Moment;
  end: moment.Moment;
  totalWidth: number;
  itemHeight: number;
  ItemRenderer: FC<ITimelineItemProps<T>>;
}

/**
 * takes displayItems and renders them according to grid settings
 */
export const RowItemsRenderer = <T extends TTimelineItemVariant>({
  displayItems,
  start,
  end,
  totalWidth,
  itemHeight,
  ItemRenderer,
}: IRowItemsRendererProps<T>): JSX.Element => {
  const startEndMs = end.diff(start, 'milliseconds');
  const pixelsPerMs = totalWidth / startEndMs;

  // sorted in reverse order for style reasons
  const reversedDisplayItems = displayItems.sort((i) => -i.start.unix());

  return (
    <>
      {reversedDisplayItems.map((displayItem) => {
        const top =
          typeof displayItem.rowOffset === 'number'
            ? itemHeight * displayItem.rowOffset
            : 0;
        const itemOffsetMins = displayItem.displayStart.diff(
          start,
          'milliseconds'
        );
        const itemDurationMins = displayItem.displayEnd.diff(
          displayItem.displayStart,
          'milliseconds'
        );
        const left = Math.round(itemOffsetMins * pixelsPerMs);
        const width = Math.round(itemDurationMins * pixelsPerMs);

        return (
          <Fragment key={`ítem-renderer-${displayItem.id}`}>
            <ItemRenderer
              timelineItem={displayItem}
              left={left}
              cellWidth={width}
              top={top}
              height={itemHeight}
            />
          </Fragment>
        );
      })}
    </>
  );
};
