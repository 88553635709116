import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const ClientApprovalBodyStyled = styled.div`
  ${flexFn('flex-start', 'center', 'column')}
  gap: ${pxToRem(40)};
  min-height: ${pxToRem(100)};
  width: 100%;
`;
