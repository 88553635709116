import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { flexFn } = Utils;

export const ConversationItemWrapperStyled = styled.div`
  position: relative;
  ${flexFn()};
  min-width: 0;
  width: 100%;
  overflow: hidden;
  cursor: pointer;

  && .MuiButtonBase-root {
    background-color: transparent;
    box-shadow: none;
  }
`;
