import { FC, useMemo } from 'react';
import { Theme, WorkplannerContactAction } from 'team-hero-ui';

import type { IAvatarStatusRenderer } from './AvatarStatusRenderer.type';
import { getContactIdFromIriOrContact } from 'helpers/contact/getContactId.helper';
import useGetImageUrl from 'hooks/useGetImageUrl.hook';
import { useGetContactItemQuery } from 'services/teamHeroApi.service';
import { NameStyled } from './AvatarStatusRenderer.styled';

const AvatarStatusRenderer: FC<IAvatarStatusRenderer> = ({
  shift,
  forbidAction = false,
  displayName = false,
  showNameWithoutHover = false,
}) => {
  const { data: contactData } = useGetContactItemQuery(
    {
      id: getContactIdFromIriOrContact(shift.contact),
    },
    { skip: !getContactIdFromIriOrContact(shift.contact) }
  );

  const { imageUrls } = useGetImageUrl({
    images: contactData?.avatar,
    thumbnailSize: 'small',
  });

  const imageUrl = Array.isArray(imageUrls) ? imageUrls[0] : imageUrls;

  const contactActionRenderer = useMemo(
    () => (
      <WorkplannerContactAction
        status='sameAsWrapper'
        avatarOnClick={() => {}}
        image={imageUrl}
        size='tiny'
        forbidAction={forbidAction}
        firstName={contactData?.firstName}
        lastName={contactData?.lastName}
        note={
          contactData?.isExternal
            ? contactData.provider?.name
            : shift.switchNote
        }
        badgeProps={{}}
        bgColor={
          contactData?.isExternal ? Theme.colors.primary.grey : undefined
        }
        displayName={displayName}
      />
    ),
    [contactData, displayName, forbidAction, imageUrl, shift]
  );

  if (!shift.contact) {
    return null;
  }
  if (showNameWithoutHover) {
    return (
      <>
        {contactActionRenderer}
        <NameStyled>
          {contactData?.firstName} {contactData?.lastName}
        </NameStyled>
      </>
    );
  }
  return contactActionRenderer;
};

export default AvatarStatusRenderer;
