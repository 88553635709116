import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getAvatarSize, Icons, Skeleton, Theme } from 'team-hero-ui';

import type { IContact } from 'interfaces/Contact.interface';
import type { ICustomContactCellRendererProps } from './ContactCellRenderer.types';
import {
  checkEmbedIsApplication,
  checkEmbedIsSystemContact,
} from 'helpers/embed/embedTypeCheck.helper';
import Avatar from 'components/Avatar';
import { EmptyCell } from 'components/cellRenderers/EmptyCellRenderer/EmptyCellRenderer';
import {
  ContactCellContainerStyled,
  SkeletonWrapper,
} from './ContactCellRenderer.styled';
import { CommonCellContentStyled } from '../Common.styled';

const ContactCellRenderer: FC<ICustomContactCellRendererProps> = ({
  contactData,
  size,
  displayName = true,
  fontSize,
  onClick,
  showSkeleton,
}) => {
  const { t } = useTranslation('general');

  // random value between 40 and 80
  const randomWidth = Math.floor(Math.random() * 40) + 40;

  if (showSkeleton) {
    return (
      <SkeletonWrapper>
        <ContactCellContainerStyled>
          <Skeleton.Avatar
            width={getAvatarSize(size)}
            height={getAvatarSize(size)}
          />
          <Skeleton.Text
            width={`calc(${randomWidth}% - ${getAvatarSize(size)}px)`}
          />
        </ContactCellContainerStyled>
      </SkeletonWrapper>
    );
  }

  if (!contactData) {
    return <EmptyCell />;
  }

  if (checkEmbedIsSystemContact(contactData)) {
    return (
      <ContactCellContainerStyled>
        <Avatar
          sourceType='resource'
          images={''}
          size={size}
          bgColor={Theme.colors.greyShades.grey5}
          thumbnailSize={'small'}
          icon={(avatarWidth) => (
            <Icons.LogoIcon
              svgColor={Theme.colors.greyShades.grey2}
              svgSize={avatarWidth * 0.75}
            />
          )}
          onClick={onClick}
        />
        {displayName && (
          <CommonCellContentStyled $fontSize={fontSize}>
            {t('common.systemContact')}
          </CommonCellContentStyled>
        )}
      </ContactCellContainerStyled>
    );
  }

  if (checkEmbedIsApplication(contactData)) {
    return (
      <ContactCellContainerStyled>
        <div>
          <Avatar
            sourceType='file'
            images={contactData.avatar || ''}
            size={size}
            firstName={contactData.firstName}
            lastName={contactData.lastName}
            thumbnailSize={'small'}
            onClick={onClick}
          />
        </div>
        {displayName && (
          <CommonCellContentStyled $fontSize={fontSize}>
            {contactData.firstName} {contactData.lastName}
          </CommonCellContentStyled>
        )}
      </ContactCellContainerStyled>
    );
  }

  return (
    <ContactCellContainerStyled>
      <div>
        <Avatar
          sourceType='resource'
          images={contactData.avatar || ''}
          size={size}
          embeddedContact={contactData}
          bgColor={
            (contactData as IContact).isExternal
              ? Theme.colors.primary.black
              : undefined
          }
          thumbnailSize={'small'}
          onClick={onClick}
        />
      </div>
      {displayName && (
        <CommonCellContentStyled $fontSize={fontSize}>
          {contactData.firstName} {contactData.lastName}
        </CommonCellContentStyled>
      )}
    </ContactCellContainerStyled>
  );
};

export default ContactCellRenderer;
