import { styled } from 'styled-components';
import { Button, device, Theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const ClientApprovalContainerStyled = styled.div`
  height: 100dvh;
  width: 100vw;
  ${flexFn('flex-start', 'center', 'column')}
  gap: ${pxToRem(20)};
  min-height: 0;
  padding-bottom: ${pxToRem(20)};
`;

export const ClientApprovalHeaderStyled = styled.div`
  ${flexFn('flex-end', 'center')}
  width: 100%;
  padding: ${pxToRem([12, 24, 12, 24])};
`;

export const TitleStyled = styled.div`
  color: ${Theme.colors.primary.white};
  font-size: ${pxToRem(30)};
`;

export const SignatureAreaWrapperStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  flex: 1;
  width: 80%;
  max-height: ${pxToRem(280)};
  @media ${device.md} {
    width: 100%;
  }
  margin-bottom: ${pxToRem(24)};
`;

export const ClientApprovalButtonSectionStyled = styled.div`
  ${flexFn('flex-end')}
  gap: ${pxToRem(25)};
  width: 100%;
`;

export const ClientApprovalButtonStyled = styled(Button)`
  @media ${device.md} {
    &.MuiButton-root.MuiButton-sizeMedium.MuiButton-text {
      padding: ${pxToRem([25, 20])};
      font-size: ${pxToRem(14)};
    }
  }
`;

export const ClientApprovalOperationsSectionStyled = styled.div`
  ${flexFn('flex-start', 'center', 'column')}
  @media ${device.md} {
    width: 80%;
  }

  @media ${device.lg} {
    width: 50%;
  }
`;

export const ApproveButtonStyled = styled(Button)`
  &.MuiButton-root.MuiButton-text.MuiButton-sizeMedium {
    padding: ${pxToRem([30, 15])};
    @media ${device.md} {
      padding: ${pxToRem([40, 35])};
    }
    font-size: ${pxToRem(31)};
  }
`;

export const ApprovalListWrapperStyled = styled.div`
  ${flexFn('flex-start', 'center', 'column')};
  flex: 1;
  width: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;

  @media ${device.sm} {
    min-width: ${pxToRem(670)};
    width: 80%;
  }

  @media ${device.lg} {
    width: 40%;
  }
`;
