import { FC } from 'react';

import type { IProjectMission } from 'interfaces/ProjectMission.interface';
import { EmptyCell } from 'components/cellRenderers/EmptyCellRenderer/EmptyCellRenderer';
import { ChipLabelStyled } from 'components/cellRenderers/ProjectTitleCellRenderer/ProjectTitleCellRenderer.styled';

interface IProjectTitleCellRendererProps {
  mission: IProjectMission;
}

export const ProjectTitleCellRenderer: FC<IProjectTitleCellRendererProps> = ({
  mission,
}) => {
  if (!mission.project || !mission.project.title) {
    return <EmptyCell />;
  }
  return (
    <ChipLabelStyled
      $calendarColorBg={mission.calendarColorBg}
      $calendarColorText={mission.calendarColorText}
    >
      {mission.project.title}
    </ChipLabelStyled>
  );
};
