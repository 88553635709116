import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const AddressCityStyled = styled.p`
  font-size: ${pxToRem(14)};
  text-transform: uppercase;
  font-weight: 700;
`;

export const CardContentStyled = styled.div`
  padding ${pxToRem([0, 12])};
`;

export const JobsHeaderStyled = styled.div`
  color: ${Theme.colors.primary.grey};
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(12)};
  margin-bottom: ${pxToRem(12)};
  span {
    font-size: ${pxToRem(24)};
  }
  p {
    font-size: ${pxToRem(15)};
    text-transform: uppercase;
    font-weight: 700;
  }
`;

export const ShiftsCountStyled = styled.div`
  ${flexFn('flex-start', 'stretch')};
  gap: ${pxToRem(8)};
`;

export const ShiftCountValueStyled = styled.span`
  font-size: ${pxToRem(14)};
  color: ${Theme.colors.greyShades.grey2};
`;

export const ShiftCountStyled = styled.div`
  ${flexFn('flex-start', 'center')};
  gap: ${pxToRem(4)};
`;
