import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ITimelineShiftCollectionViewProps } from './TimelineShiftCollectionView.type';
import Timeline from 'components/Timeline';
import { getSummedItemsAndGroupsFromShiftsByKey } from 'components/Timeline/helper/shiftTimeline.helper';
import { TimelineItemProjectShiftCollection } from 'components/Workplanner/components/WorkplannerTimelineView/components/itemRenderer';
import { ShiftCollectionLeftLegend } from 'components/Workplanner/components/WorkplannerTimelineView/components/legendRenderer/LeftLegend';
import { ShiftCollectionRightLegend } from 'components/Workplanner/components/WorkplannerTimelineView/components/legendRenderer/RightLegend';
import { calculateResolution } from 'components/Timeline/helper/timelineSettings.helper';
import GroupWrapper from '../TimelineGroupView/components/GroupWrapper';

const TimelineShiftCollectionView: FC<ITimelineShiftCollectionViewProps> = ({
  group,
  shifts,
  timelineWidth,
  timelineSize,
  timelineStart,
  timelineEnd,
  leftLegendWidth,
  rightLegendWidth,
  mainGroupKey,
  subGroupKey,
  missionId,
}) => {
  const { t } = useTranslation('general');
  const shiftCollectionRowHeight = 105;

  const groupLabel = t(`workplanner.timeline.grouplabel.${mainGroupKey}`, {
    groupLabel: group.label,
  });

  const { groups, items } = getSummedItemsAndGroupsFromShiftsByKey(
    shifts || [],
    subGroupKey
  );

  const resolution = useMemo(
    () => calculateResolution(timelineSize),
    [timelineSize]
  );

  return (
    <GroupWrapper
      start={timelineStart}
      end={timelineEnd}
      shiftItems={shifts}
      timelineWidth={timelineWidth}
      leftLegendWidth={leftLegendWidth}
      rightLegendWidth={rightLegendWidth}
      timelineSize={timelineSize}
      open
      groupLabel={groupLabel}
    >
      <Timeline
        timelineWidth={timelineWidth}
        resolution={resolution}
        timelineStart={timelineStart}
        timelineEnd={timelineEnd}
        leftLegendWidth={leftLegendWidth}
        rightLegendWidth={rightLegendWidth}
        items={items}
        rowHeight={shiftCollectionRowHeight}
        groups={groups}
        groupKey={subGroupKey}
        missionId={missionId}
        ItemRenderer={(props) => (
          <TimelineItemProjectShiftCollection
            {...props}
            missionId={missionId}
          />
        )}
        LeftLegendRenderer={(props) => <ShiftCollectionLeftLegend {...props} />}
        RightLegendRenderer={ShiftCollectionRightLegend}
      />
    </GroupWrapper>
  );
};

export default TimelineShiftCollectionView;
